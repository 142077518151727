import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Statistic,
  Card,
  Row,
  Col,
  List,
  Image,
  Spin,
  Avatar,
  Progress,
  PageHeader,
  Tag,
  Modal,
  Button,
  notification,
} from "antd";
import { UserOutlined, BulbOutlined, RightOutlined } from "@ant-design/icons";
//import { Link } from "react-router-dom";
import { getCountUsersApi } from "../../api/user.api";

import { getAccessTokenApi } from "../../api/auth";
import useAuth from "../../hooks/useAuth";
// import fincaSvg from "../../assets/img/svg/finca.svg";
// import L from "leaflet";
import { getCountHaveApi } from "../../api/have.api";
import { getCountNeedApi } from "../../api/need.api";
import {
  getUsersApi,
  getPotentialsApi,
  getUsersInfoApi,
} from "../../api/user.api";
import GoogleMapReact from "google-map-react";
import noAvatar from "../../assets/img/png/no-avatar.png";

//import "react-leaflet-markercluster/dist/styles.min.css";
import Pin from "../../assets/img/png/pin.png";
//import Marker from "../../../components/Common/marker";
import { apiKeyGoogle } from "../../api/config";
import { returnTruncate } from "../../utils/functions";
import { Link } from "react-router-dom";
import ModalUser from "../../components/Admin/ModalUser";
import Actualizacion from "../../components/Home/User/Actualizacion";
import "./User.scss";

const DefaultLocation = { lat: -0.5012984084076219, lng: -76.97750904233827 };
const DefaultZoom = 8;

export default function User({ socket }) {
  const token = getAccessTokenApi();
  const [countUserActive, setCountUserActive] = useState();
  const [countNeed, setCountNeed] = useState();
  const [countHave, setCountHave] = useState();
  const [users, setUsers] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [searchlocation, setSearchLocation] = useState(false);
  const [potentials, setPotentials] = useState();
  const [potentialsNeed, setPotentialsNeed] = useState();
  const { user, isLoading } = useAuth(); //hook
  const [userinfo, setUserinfo] = useState();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [updateInfo, setUpdateInfo] = useState(false);
  const [lastsocket, setLastsocket] = useState();

  useEffect(() => {
    socket.on("friendrequestResponse", (data) => {
      console.log(data);

      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
    socket.on("messageResponse", (data) => {
      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
  }, [socket]);

  //const [countEncuestas, setCountEncuestas] = useState();
  //const [fincas, setFincas] = useState([]);
  useEffect(() => {
    getUsersInfoApi(token, user.id).then((response) => {
      setUserinfo(response.user);
    });
  }, [token, updateInfo]);

  useEffect(() => {
    // getCountFincaApi(token).then((response) => {
    //   setCountFincas(response.count);
    // });

    // getCountEncuestaApi(token).then((response) => {
    //   setCountEncuestas(response.count);
    // });
    setLoadingData(true);

    getUsersInfoApi(token, user.id).then((response) => {
      console.log(response);
      setUserinfo(response.user);

      var tabStart = 0;
      if (response.user.entrepreneurships.length > 0) {
        if (response.user.entrepreneurships[0].question1 != null) {
          tabStart = 4;
        } else if (response.user.entrepreneurships[0].haves.length > 0) {
          tabStart = 3;
        } else if (response.user.entrepreneurships[0].needs.length > 0) {
          tabStart = 2;
        } else {
          tabStart = 1;
        }
      }

      if (tabStart < 4) {
        setIsVisibleModal(true);
        setModalTitle(
          "Complete esta infomación para empezar a conectar con otros emprendedores"
        );
        setmodalContent(
          <Actualizacion
            userData={response.user}
            tabStart={tabStart}
            setIsVisibleModal={setIsVisibleModal}
            setUpdateInfo={setUpdateInfo}
            hasEntrepreneurshipData={tabStart > 0}
          />
        );
      }
    });

    getUsersApi(token).then((response) => {
      //console.log(response);
      setUsers(response.users);
      setLoadingData(false);
    });

    getPotentialsApi(user.id, 5, 40, 10).then((response) => {
      //console.log(response);
      setPotentials(response.users);
    });

    getPotentialsApi(user.id, 5, 10, 40).then((response) => {
      //console.log(response);
      setPotentialsNeed(response.users);
    });

    getCountUsersApi(token, true).then((response) => {
      setCountUserActive(response.count);
    });

    getCountHaveApi(token, true).then((response) => {
      setCountHave(response.count);
    });

    getCountNeedApi(token, true).then((response) => {
      setCountNeed(response.count);
    });

    // getCountUsersApi(token, false).then((response) => {
    //   setCountUserInactive(response.count);
    // });

    // getAllFincasApi(token).then((response) => {
    //   setFincas(response.fincas);
    // });
  }, [token]);

  // const iconFinca = new L.Icon({
  //   iconUrl: fincaSvg,
  //   iconRetinaUrl: fincaSvg,
  //   iconSize: [64, 64],
  //   iconAnchor: [32, 64],
  //   popupAnchor: [0, -46],
  //   shadowUrl: null,
  //   shadowSize: null,
  //   shadowAnchor: null,
  // });

  return (
    <>
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        closable={false}
        width={1400}
      >
        {modalContent}
      </ModalUser>
      <PageHeader
        className="site-page-header left"
        title={userinfo?.name}
        subTitle="Este es tu dashboard con información importante de la plataforma"
        tags={<Tag color="orange">{userinfo?.entrepreneurships[0]?.name}</Tag>}
        breadcrumb={
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>Emprendedor</Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          </Breadcrumb>
        }
      />
      <div className="site-statistic-demo-card">
        <Row gutter={24}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card>
              <Statistic
                title="Emprendedores registrados"
                value={countUserActive}
                //precision={2}
                valueStyle={{ color: "#3f8600" }}
                prefix={<UserOutlined />}
                //suffix="%"
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card>
              <Statistic
                title="Conocimientos disponibles"
                value={countHave}
                //precision={2}
                valueStyle={{ color: "#ffc107" }}
                prefix={<BulbOutlined />}
                //suffix="%"
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card>
              <Statistic
                title="Conocimientos requeridos"
                value={countNeed}
                //precision={2}
                valueStyle={{ color: "#3f51b5" }}
                prefix={<BulbOutlined />}
                //suffix="%"
              />
            </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Card
              title={<span>Mapa de emprededores</span>}
              // extra={
              //   <Button
              //     type="dashed"
              //     onClick={() => currentLocation()}
              //     icon={<AimOutlined />}
              //     size="small"
              //     className="mini"
              //     loading={searchlocation}
              //   >
              //     buscar mi ubicación
              //   </Button>
              // }
              bodyStyle={{ padding: "0" }}
              //style={{ height: "300px", width: "100%" }}
            >
              {/* <MapPicker
                defaultLocation={defaultLocation}
                zoom={zoom}
                mapTypeId="roadmap"
                style={{ height: "300px" }}
                onChangeLocation={handleChangeLocation}
                onChangeZoom={handleChangeZoom}
                // apiKey="AIzaSyDy8AE5RqF-ozGiI8J7r-U1vnb3AwQCY-o"
                apiKey={apiKeyGoogle}
              /> */}
              <div style={{ height: "412px", width: "100%" }}>
                <GoogleMapReact
                  defaultZoom={zoom}
                  defaultCenter={defaultLocation}
                  bootstrapURLKeys={{
                    key: apiKeyGoogle,
                    language: "es",
                    region: "ec",
                    libraries: [],
                  }}
                  //onClick={handleChangeLocation}
                  //center={location}
                >
                  {!loadingData ? (
                    users.map((user) => (
                      <Image
                        key={user.id}
                        width={32}
                        src={Pin}
                        lat={user.lat}
                        lng={user.lon}
                        preview={false}
                        style={{ marginTop: "-32px", marginLeft: "-16px" }}
                      />
                    ))
                  ) : (
                    // <GoogleMapReact.Marker
                    //   key={"mylocation"}
                    //   text={"Mi ubicación"}
                    //   lat={location.lat}
                    //   lng={location.lng}
                    // />
                    <Spin width={100} lat={location.lat} lng={location.lng} />
                  )}
                </GoogleMapReact>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={7} xl={7}>
            <Card
              title={<span>A quién puedo ayudar</span>}
              // extra={
              //   <Link to={"/user/conexiones"}>
              //     <RightOutlined />
              //   </Link>
              // }
            >
              <List
                itemLayout="horizontal"
                dataSource={potentials}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={noAvatar} />}
                      title={
                        <div class="truncate">
                          {item.name.toUpperCase() +
                            " " +
                            item.lastname.toUpperCase()}
                        </div>
                      }
                      description={
                        <Progress
                          percent={
                            item.porcentaje >= 100 ? 100 : item.porcentaje
                          }
                          strokeColor={
                            item.porcentaje > 50
                              ? "#1ABC9C"
                              : item.porcentaje > 30
                              ? "#F5B041"
                              : "#F9E79F"
                          }
                          size="small"
                        />
                      }
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={7} xl={7}>
            <Card
              title={<span>Quién me puede ayudar</span>}
              // extra={
              //   <Link to={"/user/conexiones"}>
              //     <RightOutlined />
              //   </Link>
              // }
            >
              <List
                itemLayout="horizontal"
                dataSource={potentialsNeed}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={noAvatar} />}
                      title={
                        <div class="truncate">
                          {item.name.toUpperCase() +
                            " " +
                            item.lastname.toUpperCase()}
                        </div>
                      }
                      description={
                        <Progress
                          percent={
                            item.porcentaje >= 100 ? 100 : item.porcentaje
                          }
                          strokeColor={
                            item.porcentaje > 50
                              ? "#1ABC9C"
                              : item.porcentaje > 30
                              ? "#F5B041"
                              : "#F9E79F"
                          }
                          size="small"
                        />
                      }
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
