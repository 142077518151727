import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  InputNumber,
  Radio,
} from "antd";

import { getAccessTokenApi } from "../../../../../api/auth";
import {
  UpdateHaveApi,
  AddHaveApi,
  DeleteHaveApi,
} from "../../../../../api/have.api";
//import useAuth from "../../../../../hooks/useAuth";

import "./EditHaveForm.scss";
import SelectKnowledgeForm from "../../../../../components/Admin/Knowledge/SelectKnowledgeForm";
import { getEntrepreneurshipByIdApi } from "../../../../../api/entrepreneurship.api";

export default function EditHaveForm(props) {
  const {
    have,
    setIsVisibleModal,
    setReloadHaves,
    buttonName,
    addSlag,
    entrepreneurshipData,
  } = props;
  const [haveData, setHaveData] = useState({});
  //const { user } = useAuth();
  //const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const token = getAccessTokenApi();
  const [formHave] = Form.useForm();

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    // setHaveData({
    //   code: "",
    //   name: "",
    // });
  };

  useEffect(() => {
    if (have) {
      formHave.setFieldsValue(have);
    } else {
      formHave.setFieldsValue({
        entrepreneurshipId: null,
        hours: null,
        id: null,
        knowledgeId: null,
        other: null,
        years: null,
      });
    }
    // getEntrepreneurshipByIdApi(token, user.id).then((response) => {
    //   setEntrepreneurshipData(response.entrepreneurship);
    // });
  }, [have]);

  const addUpdateHave = (e) => {
    e.preventDefault();
    let haveUpdate = haveData;
    if (!haveUpdate.name || !haveUpdate.code) {
      notification["error"]({
        message: "El nombre y el código es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddHaveApi(token, haveUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadHaves(true);
        setIsVisibleModal(false);
        //resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateHaveApi(token, haveUpdate, have.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadHaves(true);
        setIsVisibleModal(false);
        setHaveData({
          ...haveData,
        });
      });
    } else {
      DeleteHaveApi(token, have.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadHaves(true);
        setIsVisibleModal(false);
      });
    }
  };

  const onFinish = (values) => {
    const token = getAccessTokenApi();
    if (addSlag === "add") {
      AddHaveApi(token, {
        ...values,
        entrepreneurshipId: entrepreneurshipData.id,
      }).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadHaves(true);
        setIsVisibleModal(false);
        //resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateHaveApi(
        token,
        { ...values, entrepreneurshipId: entrepreneurshipData.id },
        have.id
      ).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadHaves(true);
        setIsVisibleModal(false);
        setHaveData({
          ...haveData,
        });
      });
    } else {
      DeleteHaveApi(token, have.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadHaves(true);
        setIsVisibleModal(false);
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message(errorInfo);
    notification["error"]({
      message: "Revisa los errores en la información ingresada",
      duration: 3,
    });
  };

  return (
    <div className="edit-have-form">
      <Form
        form={formHave}
        name="have"
        className="form-edit"
        //onSubmit={addUpdateHave}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{ remember: false }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <SelectKnowledgeForm
              // atributoData={city}
              // setAtributoData={setCity}
              //handleChange={handleChangeYotengo}
              name={"knowledgeId"}
              nameOther={"other"}
              edit={addSlag === "edit" || addSlag === "delete"}
              values={have}
            ></SelectKnowledgeForm>
          </Col>
          <Col xs={24} sm={24} md={7} lg={7} xl={7}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name="hours"
              label="Horas requeridas"
              rules={[
                {
                  required: true,
                  message: "Seleccione las horas entregadas",
                },
              ]}
            >
              {/* <Input placeholder="Horas requeridas" /> */}
              {/* <InputNumber
                              placeholder="Horas requeridas"
                              maxLength={4}
                            /> */}
              <Radio.Group>
                <Radio value={2}>2</Radio>
                <Radio value={4}>4</Radio>
                <Radio value={6}>6</Radio>
                <Radio value={8}>8</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={22} sm={22} md={7} lg={7} xl={7}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name="years"
              label="Años de experiencia"
              rules={[
                {
                  required: true,
                  message: "Seleccione los años de experiencia",
                },
              ]}
            >
              {/* <Input placeholder="Años de experiencia" /> */}
              {/* <InputNumber placeholder="Años de experiencia" maxLength={4} /> */}
              <Radio.Group>
                <Radio value={1}>1</Radio>
                <Radio value={2}>2</Radio>
                <Radio value={4}>4</Radio>
                <Radio value={6}>6</Radio>
                <Radio value={8}>8</Radio>
                <Radio value={10}>10 o más</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
