import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Row, Col, DatePicker, Button } from "antd";
import { Bar } from "@ant-design/charts";
import { getAccessTokenApi } from "../../../api/auth";

import "./Indicadores.scss";
//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";

import { getStoreEmployeesApi } from "../../../api/store.api";

export default function Ferias() {
  const token = getAccessTokenApi();
  //const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [disabled2, setDisabled2] = useState(true);
  const [disabled3, setDisabled3] = useState(true);
  const [disabled4, setDisabled4] = useState(true);
  const [total, setTotal] = useState(0);
  const [participants, setParticipants] = useState(0);

  useEffect(() => {
    getStoreEmployeesApi(token).then((response) => {
      //console.log(response);
      let citys1 = response.stores;
      var subtotal = 0;
      citys1.map((city) => (subtotal = subtotal + city.ferias));
      setTotal(subtotal);
      subtotal = 0;
      citys1.map((city) => (subtotal = subtotal + city.participants));
      setParticipants(subtotal);
      setData(response.stores);
    });
  }, [token]);

  const config = {
    data,
    xField: "ferias",
    yField: "type",
    seriesField: "type",
    //height: 600,
    legend: {
      position: "top-left",
    },
    label: {
      position: "middle",
      style: {
        fill: "#000",
        opacity: 1,
      },
    },
  };

  const config2 = {
    data,
    xField: "participants",
    yField: "type",
    seriesField: "type",
    //height: 600,
    legend: {
      position: "top-left",
    },
    label: {
      position: "middle",
      style: {
        fill: "#000",
        opacity: 1,
      },
    },
  };

  const ref = useRef([]);

  // export image
  const downloadImage = () => {
    ref.current[0]?.downloadImage();
  };

  // export image
  const downloadImage2 = () => {
    ref.current[1]?.downloadImage();
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Gráficas</Breadcrumb.Item>
        <Breadcrumb.Item>Ferias</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        {/* <Row gutter={16}>
          <Col span={12}>
            <RangePicker
              style={{ width: "100%" }}
              picker="month"
              onChange={onChange}
            />
          </Col>
          <Col span={12}>
            { <SelectMultiFinca setFincaId={setFincaId}></SelectMultiFinca> }
          </Col>
        </Row> */}
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <br />
            <h2>
              Número de ferias promedio que se organizan al año por provincia (
              {total} totales)
            </h2>

            <Bar
              {...config}
              onReady={(plot) => {
                ref.current[0] = plot;
                setDisabled(false);
              }}
            />
            <br />
            <Button
              disabled={disabled}
              htmlType="button"
              className="btn-submit"
              onClick={downloadImage}
            >
              Guardar gráfico
            </Button>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <br />
            <h2>
              Número de participantes por feria por provincia ({participants}{" "}
              totales)
            </h2>

            <Bar
              {...config2}
              onReady={(plot) => {
                ref.current[1] = plot;
                setDisabled2(false);
              }}
            />
            <br />
            <Button
              disabled={disabled2}
              htmlType="button"
              className="btn-submit"
              onClick={downloadImage2}
            >
              Guardar gráfico
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}
