//export const basePath = "https://api-ci.aplios.software";

//prod
export const basePath = "https://www.api-innovandoamazonia.emprende.ec";
//dev
//export const basePath = "http://localhost:3980";

export const apiVersion = "v1";

//prod
export const apiKeyGoogle = "AIzaSyDy8AE5RqF-ozGiI8J7r-U1vnb3AwQCY-o";
//dev
//export const apiKeyGoogle = "AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8";
