import React, { useState, useEffect } from "react";
import { Select, Form } from "antd";

import { getStagesApi } from "../../../../api/stage.api";

import "./SelectStageForm.scss";

const { Option } = Select;

export default function SelectStageForm(props) {
  const { atributoData, setAtributoData } = props;
  const [stageData, setStageData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [stageId, setStageId] = useState();

  useEffect(() => {
    getStagesApi().then((response) => {
      setStageData(response.stages);
      //setStageId(atributoData);
    });
    setLoadingData(false);
  }, [loadingData]);

  const handleAtributoChange = (value) => {
    setStageId(value);
    setAtributoData(value);
  };

  return (
    <div className="select-stage-form">
      <Form.Item
        label="Etapa en la que se encuentra"
        name="stageId"
        rules={[{ required: true, message: "Seleccione una etapa" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        initialValue={atributoData}
      >
        <Select
          placeholder="Seleccione la etapa"
          value={stageId}
          onChange={handleAtributoChange}
          //disabled={disabled}
        >
          {stageData && stageData.map
            ? stageData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
    </div>
  );
}
