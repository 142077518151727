import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";

import {
  Button,
  PageHeader,
  Form,
  Row,
  InputNumber,
  Card,
  Breadcrumb,
  Input,
  Col,
  notification,
  DatePicker,
  Skeleton,
} from "antd";
import {
  SaveOutlined,
  UserOutlined,
  PushpinOutlined,
  AimOutlined,
  BulbOutlined,
} from "@ant-design/icons";

import "./Emprendimiento.scss";
//import { getEntrepreneurshipByIdApi } from "../../../api/entrepreneurship.api";
import {
  getEntrepreneurshipByIdApi,
  UpdateEntrepreneurshipApi,
} from "../../../api/entrepreneurship.api";
import { AddOtherApi, getOtherByIdApi } from "../../../api/other.api";

import SelectTypeForm from "../../../components/Admin/Type/SelectTypeForm";
import SelectSectorForm from "../../../components/Admin/Sector/SelectSectorForm";
import SelectStageForm from "../../../components/Admin/Stage/SelectStageForm/SelectStageForm";
import SelectProcessForm from "../../../components/Admin/Process/SelectProcessForm";
import SelectCommunityForm from "../../../components/Admin/Community/SelectCommunityForm";

import useAuth from "../../../hooks/useAuth";
import moment from "moment";

const Emprendimiento = ({ socket }) => {
  const { user, isLoading } = useAuth(); //hook
  const [formEmprendimiento] = Form.useForm();
  const token = getAccessTokenApi();
  const [reloadEmprendimiento, setReloadEmprendimiento] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const [type, setType] = useState({});
  const [sector, setSector] = useState();
  const [stage, setStage] = useState();
  const [process, setProcess] = useState();
  const [community, setCommunity] = useState();
  const [loadings, setLoadings] = useState(false);
  const [userData, setUserData] = useState({});
  const [SelectTypeControl, SetSelectTypeControl] = useState(
    <Skeleton.Input active={true} />
  );
  const [SelectSectorControl, SetSelectSectorControl] = useState(
    <Skeleton.Input active={true} />
  );
  const [SelectCommunityControl, SetSelectCommunityControl] = useState(
    <Skeleton.Input active={true} />
  );
  const [SelectProcessControl, SetSelectProcessControl] = useState(
    <Skeleton.Input active={true} />
  );

  const [lastsocket, setLastsocket] = useState();

  useEffect(() => {
    socket.on("friendrequestResponse", (data) => {
      console.log(data);

      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
    socket.on("messageResponse", (data) => {
      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
  }, [socket]);

  useEffect(() => {
    getEntrepreneurshipByIdApi(token, user.id).then((response) => {
      setEntrepreneurshipData(response.entrepreneurship);
      console.log(response.entrepreneurship);
      let entrepreneurshipData = response.entrepreneurship;
      entrepreneurshipData.creation = moment(entrepreneurshipData.creation);
      formEmprendimiento.setFieldsValue(entrepreneurshipData);

      const arrTypes = [];
      Object.keys(entrepreneurshipData.types).forEach((key) =>
        arrTypes.push(entrepreneurshipData.types[key].id).toString()
      );
      var isOtherTypes = arrTypes.find((element) => {
        return element === 8;
      });
      if (isOtherTypes) {
        getOtherByIdApi(token, response.entrepreneurship.id, "type").then(
          (response) => {
            SetSelectTypeControl(
              <SelectTypeForm
                atributoData={arrTypes}
                setAtributoData={setType}
                deafultValue={response.other.description}
              ></SelectTypeForm>
            );
            console.log(response.other.description);
          }
        );
      } else {
        SetSelectTypeControl(
          <SelectTypeForm
            atributoData={arrTypes}
            setAtributoData={setType}
            deafultValue={""}
          ></SelectTypeForm>
        );
      }

      const arrSectors = [];
      Object.keys(entrepreneurshipData.sectors).forEach((key) =>
        arrSectors.push(entrepreneurshipData.sectors[key].id).toString()
      );
      var isOtherSectors = arrSectors.find((element) => {
        return element === 18;
      });
      if (isOtherSectors) {
        getOtherByIdApi(token, response.entrepreneurship.id, "sector").then(
          (response) => {
            console.log(response);
            SetSelectSectorControl(
              <SelectSectorForm
                atributoData={arrSectors}
                setAtributoData={setSector}
                deafultValue={response.other.description}
              ></SelectSectorForm>
            );
            console.log(response.other.description);
          }
        );
      } else {
        SetSelectSectorControl(
          <SelectSectorForm
            atributoData={arrSectors}
            setAtributoData={setSector}
            deafultValue={""}
          ></SelectSectorForm>
        );
      }
      const arrCommunitys = [];
      Object.keys(entrepreneurshipData.communities).forEach((key) =>
        arrCommunitys.push(entrepreneurshipData.communities[key].id).toString()
      );
      var isOtherCommunity = arrCommunitys.find((element) => {
        return element === 10;
      });
      if (isOtherCommunity) {
        getOtherByIdApi(token, response.entrepreneurship.id, "community").then(
          (response) => {
            SetSelectCommunityControl(
              <SelectCommunityForm
                atributoData={arrCommunitys}
                setAtributoData={setCommunity}
                deafultValue={response.other.description}
              ></SelectCommunityForm>
            );
            console.log(response.other.description);
          }
        );
      } else {
        SetSelectCommunityControl(
          <SelectCommunityForm
            atributoData={arrCommunitys}
            setAtributoData={setCommunity}
            deafultValue={""}
          ></SelectCommunityForm>
        );
      }

      if (entrepreneurshipData.processId === 7) {
        getOtherByIdApi(token, response.entrepreneurship.id, "process").then(
          (response) => {
            setProcess(entrepreneurshipData.processId);
            SetSelectProcessControl(
              <SelectProcessForm
                atributoData={entrepreneurshipData.processId}
                setAtributoData={setProcess}
                deafultValue={response.other.description}
              ></SelectProcessForm>
            );
            console.log(response.other.description);
          }
        );
      } else {
        SetSelectProcessControl(
          <SelectProcessForm
            atributoData={process}
            setAtributoData={setProcess}
            deafultValue={""}
          ></SelectProcessForm>
        );
      }

      // setGenre(response.user.genreId);
      //setLoadingData(false);
    });

    setReloadEmprendimiento(false);
  }, [token, user.id]);

  const onFinishEmprendimiento = (values) => {
    console.log("Success:", values);
    setLoadings(true);

    // const entrepreneurshipAdd = {
    //   name: values.businessname,
    //   description: values.description,
    //   //invoice: values.invoice ?? false,
    //   creation: values.creation,
    //   partners: values.partner,
    //   //invoiceanual: values.invoiceanual,
    //   //employees: values.employees,
    //   stageId: values.stage,
    //   processId: values.process,
    //   //academyId: values.academy,
    //   userId: userData.id,
    //   communities: values.community,
    //   sectors: values.sector,
    //   types: values.type,
    //   medals: values.medals,
    // };
    //setEntrepreneurshipData(values);

    UpdateEntrepreneurshipApi(values, entrepreneurshipData.id).then(
      (result) => {
        //console.log(result);
        if (result.status) {
          //setEntrepreneurshipData(result.entrepreneurship);

          // values.community.forEach((d) => {
          //   //console.log(d);
          //   AddEntrepreneurshipComunityApi({
          //     entrepreneurshipId: result.entrepreneurship.id,
          //     communityId: d,
          //   });
          // });

          if (values.communityOther) {
            AddOtherApi({
              table: "community",
              description: values.communityOther,
              entrepreneurshipId: entrepreneurshipData.id,
            });
          }

          if (values.processOther) {
            AddOtherApi({
              table: "process",
              description: values.processOther,
              entrepreneurshipId: entrepreneurshipData.id,
            });
          }

          if (values.sectorOther) {
            AddOtherApi({
              table: "sector",
              description: values.sectorOther,
              entrepreneurshipId: entrepreneurshipData.id,
            });
          }

          if (values.typeOther) {
            AddOtherApi({
              table: "type",
              description: values.typeOther,
              entrepreneurshipId: entrepreneurshipData.id,
            });
          }

          notification["success"]({
            message: result.message,
          });
          //next();
          setLoadings(false);
        } else {
          notification["error"]({
            message: result.message,
          });
          setLoadings(false);
        }
      }
    );
    //next();
  };

  const onFinishFailEdemprendimiento = (errorInfo) => {
    console.log("Failed:", errorInfo);
    notification["error"]({
      message: "Revisa los errores en la información ingresada",
      duration: 3,
    });
  };

  return (
    <div className="emprendimiento">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Emprendedor</Breadcrumb.Item>
        <Breadcrumb.Item>Perfil</Breadcrumb.Item>
        <Breadcrumb.Item>Emprendimiento</Breadcrumb.Item>
      </Breadcrumb>
      <Form
        name="emprendimiento"
        form={formEmprendimiento}
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        initialValues={{ remember: false }}
        onFinish={onFinishEmprendimiento}
        onFinishFailed={onFinishFailEdemprendimiento}
        autoComplete="off"
      >
        <PageHeader
          className="site-page-header header-left"
          // onBack={() => null}
          style={{ textAlign: "left", justifyContent: "start" }}
          title={
            <>
              <BulbOutlined style={{ fontSize: "30px" }} />
              <span> Emprendimiento</span>
            </>
          }
          // subTitle="This is a subtitle"
        />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={10} xl={10}>
            <Form.Item
              label="Nombre del emprendimiento"
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name="name"
              rules={[
                {
                  required: true,
                  message: "Ingrese el nombre del emprendimiento",
                },
              ]}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Nombre del emprendimiento"
                //value={userData.name}
                maxLength={50}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={14} xl={14}>
            <Form.Item
              label="Descripción del emprendimiento"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Ingrese la descripción del emprendimiento",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Descripción del emprendimiento"
                maxLength={300}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              label="Inicio de actividades"
              name="creation"
              rules={[
                {
                  required: true,
                  message: "Seleccione la fecha de inicio de actividades",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {SelectTypeControl}
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {SelectSectorControl}
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <SelectStageForm
              atributoData={stage}
              setAtributoData={setStage}
            ></SelectStageForm>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <Form.Item
              label="Número de socios"
              name="partner"
              rules={[
                {
                  required: true,
                  message: "Ingrese el número de socios",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <InputNumber
                //prefix={<UserOutlined />}
                placeholder="Número de socios"
                maxLength={4}
                //value={userData.name}
                //defaultValue={1999}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {/* <SelectProcessForm
              atributoData={process}
              setAtributoData={setProcess}
            ></SelectProcessForm> */}
            {SelectProcessControl}
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            {SelectCommunityControl}
          </Col>
          <Col xs={24} sm={24} md={12} lg={16} xl={16}>
            <Form.Item
              label="Logros - Reconocimientos del emprendimiento"
              name="medals"
              // rules={[
              //   {
              //     required: true,
              //     message:
              //       "Ingrese los logros - reconocimientos de tu emprendimiento",
              //   },
              // ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Logros - Reconocimientos"
                maxLength={300}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <SelectAcademyForm
                atributoData={academy}
                setAtributoData={setAcademy}
              ></SelectAcademyForm>
            </Col> */}
        </Row>
        {/* <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                name="invoice"
                valuePropName="checked"
                label="¿Estás facturando?"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Checkbox checked={checkNick} onChange={onCheckboxChange}>
                  Seleccione si ya estás entregando facturas
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={5} xl={5}>
              {checkNick && (
                <Form.Item
                  name="invoiceanual"
                  label="¿Cuánto facturas por año?"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: checkNick,
                      message: "Ingrese las facturas por año",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Ingrese las facturas por año"
                    maxLength={6}
                  />
                </Form.Item>
              )}
            </Col>
            <Col xs={24} sm={24} md={12} lg={5} xl={5}>
              {checkNick && (
                <Form.Item
                  name="employees"
                  label="Número de empleados"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: checkNick,
                      message: "Ingrese el número de empleados",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Número de empleados"
                    maxLength={6}
                  />
                </Form.Item>
              )}
            </Col>
          </Row> */}
        <br></br>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loadings}
                className="btnBig"
              >
                Guardar <SaveOutlined />
              </Button>
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button type="deafult">
                  <ArrowLeftOutlined /> Anteior
                </Button>
              </Form.Item>
            </Col> */}
        </Row>
      </Form>
    </div>
  );
};

export default Emprendimiento;
