import { basePath } from "./config";

export async function getEntrepreneurshipsApi() {
  const url = `${basePath}/entrepreneurships`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      //Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export function UpdateEntrepreneurshipApi(
  entrepreneurship,
  entrepreneurshipId
) {
  const url = `${basePath}/entrepreneurship/update/${entrepreneurshipId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      //Authorization: token,
    },
    body: JSON.stringify(entrepreneurship),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function UpdateEntrepreneurshipNeedsApi(variables, entrepreneurshipId) {
  const url = `${basePath}/entrepreneurship/updateneeds/${entrepreneurshipId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      //Authorization: token,
    },
    body: JSON.stringify(variables),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function UpdateEntrepreneurshipExtrasApi(variables, entrepreneurshipId) {
  const url = `${basePath}/entrepreneurship/updateextras/${entrepreneurshipId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      //Authorization: token,
    },
    body: JSON.stringify(variables),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function AddEntrepreneurshipApi(entrepreneurship) {
  const url = `${basePath}/entrepreneurship/create`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      //Authorization: token,
    },
    body: JSON.stringify(entrepreneurship),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function AddEntrepreneurshipComunityApi(entrepreneurshipComunity) {
  const url = `${basePath}/entrepreneurship/comunity/create`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      //Authorization: token,
    },
    body: JSON.stringify(entrepreneurshipComunity),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function AddEntrepreneurshipInitialApi(parameters) {
  const url = `${basePath}/entrepreneurship/initial/create`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      //Authorization: token,
    },
    body: JSON.stringify(parameters),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function DeleteEntrepreneurshipApi(token, entrepreneurshipId) {
  const url = `${basePath}/entrepreneurship/delete/${entrepreneurshipId}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getEntrepreneurshipByIdApi(token, id) {
  const url = `${basePath}/entrepreneurship/${id}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getEntrepreneurshipPartnersApi(token) {
  const url = `${basePath}/entrepreneurship-partners`;
  //console.log(url);
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getEntrepreneurshipCapitalApi(token, province) {
  const url = `${basePath}/entrepreneurship-capital?province=${province}`;
  //console.log(url);
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getDiagnosticGroupApi(token, province) {
  const url = `${basePath}/entrepreneurship-diagnostic?province=${province}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getDiagnosticSkillsGroupApi(token, province, option) {
  const url = `${basePath}/entrepreneurship-skills?province=${province}&option=${option}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getEntrepreneurshipCapitalPasadoApi(token, province) {
  const url = `${basePath}/entrepreneurship-capitalpasado?province=${province}`;
  //console.log(url);
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getEntrepreneurshipFacturadoApi(token, province) {
  const url = `${basePath}/entrepreneurship-facturado?province=${province}`;
  //console.log(url);
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getEntrepreneurshipFacturadoPasadoApi(token, province) {
  const url = `${basePath}/entrepreneurship-facturadopasado?province=${province}`;
  //console.log(url);
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getEntrepreneurshipEmpleadosApi(token, province) {
  const url = `${basePath}/entrepreneurship-empleados?province=${province}`;
  //console.log(url);
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getEntrepreneurshipEmpleadosPasadoApi(token, province) {
  const url = `${basePath}/entrepreneurship-empleadospasado?province=${province}`;
  //console.log(url);
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
