import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Empty, Tag, Row, Col, Form, Button } from "antd";
import { Table } from "ant-table-extensions";
import { Bar } from "@ant-design/charts";
import { getAccessTokenApi } from "../../../api/auth";
import SelectAllCityForm from "../../../components/Admin/City/SelectCityForm/SelectAllCityForm";
import SelectRoleOneForm from "../../../components/Admin/Role/SelectRoleForm/SelectRoleOneForm";
import SelectCommunityOneForm from "../../../components/Admin/Community/SelectCommunityForm/SelectCommunityOneForm";
import SelectProvinceAllForm from "../../../components/Admin/Province/SelectProvinceForm/SelectProvinceAllForm";
import "./Indicadores.scss";
//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";
import ExportOutlined from "@ant-design/icons/ExportOutlined";
import SearchOutlined from "@ant-design/icons/SearchOutlined";

import { getStoresApi } from "../../../api/store.api";

export default function Tiendas() {
  const token = getAccessTokenApi();
  const [usersActive, setUsersActive] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    getStoresApi(token).then((response) => {
      //console.log(response.users);
      setUsersActive(response.stores);
      setLoadingData(false);
    });
  }, [token]);

  const columns = [
    {
      title: "ID",
      width: 50,
      dataIndex: "id",
      key: "ci",
      fixed: "left",
    },
    {
      title: "Provincia",
      width: 120,
      dataIndex: ["provincestore", "name"],
      key: "provincestoreId",
    },
    {
      title: "Nombres",
      width: 250,
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Cargo",
      width: 320,
      dataIndex: "cargo",
      key: "cargo",
    },
    {
      title: "Email",
      width: 220,
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Latitud",
      width: 120,
      dataIndex: "lat",
      key: "lat",
    },
    {
      title: "Longitud",
      width: 120,
      dataIndex: "lon",
      key: "lon",
    },
    {
      title: "Teléfono",
      width: 120,
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Género",
      width: 120,
      dataIndex: ["genre", "name"],
      key: "genreId",
    },
    {
      title: "Número de empleados",
      width: 120,
      dataIndex: "employees",
      key: "employees",
    },
    {
      title: "Estrategias de comercialización",
      width: 420,
      dataIndex: "strategies",
      key: "strategies",
    },
    {
      title: "Tiendas físicas",
      width: 100,
      dataIndex: "stores",
      key: "stores",
    },
    {
      title: "Fecha creación",
      width: 160,
      dataIndex: "creation",
      key: "creation",
    },
    {
      title: "Dirección",
      width: 220,
      dataIndex: "direction",
      key: "direction",
    },
    {
      title: "Facturación al año",
      width: 120,
      dataIndex: "facturation",
      key: "facturation",
    },
    {
      title: "Emprendimientos al año",
      width: 120,
      dataIndex: "empredimientos",
      key: "empredimientos",
    },
    {
      title: "Productos al año",
      width: 120,
      dataIndex: "products",
      key: "products",
    },
    {
      title: "Ganacia (Modelo)",
      width: 320,
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Tiendas on line",
      width: 100,
      dataIndex: "onlinestores",
      key: "onlinestores",
    },
    {
      title: "Fecha creación",
      width: 160,
      dataIndex: "onlinecreation",
      key: "onlinecreation",
    },
    {
      title: "Dirección web",
      width: 180,
      dataIndex: "onlinedirection",
      key: "onlinedirection",
    },
    {
      title: "Facturación al año",
      width: 120,
      dataIndex: "onlinefacturation",
      key: "onlinefacturation",
    },
    {
      title: "Emprendimientos al año",
      width: 120,
      dataIndex: "onlineempredimientos",
      key: "onlineempredimientos",
    },
    {
      title: "Productos al año",
      width: 120,
      dataIndex: "onlineproducts",
      key: "onlineproducts",
    },
    {
      title: "Ganacia (Modelo)",
      width: 320,
      dataIndex: "onlimemodel",
      key: "onlimemodel",
    },
    {
      title: "Ferias",
      width: 100,
      dataIndex: "ferias",
      key: "ferias",
    },
    {
      title: "Participantes de las ferias",
      width: 100,
      dataIndex: "feriaparticipants",
      key: "feriaparticipants",
    },
    {
      title: "Espacio propio",
      width: 100,
      dataIndex: "space",
      key: "space",
    },
    {
      title: "Ferias más representativas",
      width: 320,
      dataIndex: "representatives",
      key: "representatives",
    },
    {
      title: "Presupuesto",
      width: 100,
      dataIndex: "budget",
      key: "budget",
    },
    {
      title: "Ferias en otras provincias",
      width: 100,
      dataIndex: "other",
      key: "other",
    },
    {
      title: "Ganacia (Modelo)",
      width: 320,
      dataIndex: "feriamodel",
      key: "feriamodel",
    },
    {
      title: "Eventos",
      width: 100,
      dataIndex: "events",
      key: "events",
    },
    {
      title: "Gestión de eventos",
      width: 150,
      dataIndex: "gestion",
      key: "gestion",
    },
    {
      title: "Provincias",
      width: 100,
      dataIndex: "eventlocation",
      key: "eventlocation",
    },
    {
      title: "Presupuesto",
      width: 100,
      dataIndex: "eventbudget",
      key: "eventbudget",
    },
    {
      title: "Ganacia (Modelo)",
      width: 320,
      dataIndex: "eventmodel",
      key: "eventmodel",
    },
    {
      title: "Otros mecanismos",
      width: 420,
      dataIndex: "othercomercial",
      key: "othercomercial",
    },
  ];

  const fields: ITableExportFields = {
    id: "ID",
    provincia: {
      header: "Provincia",
      formatter: (_fieldValue, record) => {
        return record.provincestore.name;
      },
    },
    name: "Nombres",
    genero: {
      header: "Genero",
      formatter: (_fieldValue, record) => {
        return record.genre.name;
      },
    },
    cargo: "Cargo que ocupa",
    email: "Email",
    lat: "Latitud",
    lon: "Longitud",
    phone: "Teléfono",
    employees: "Número de encargados",
    strategies: "Estrategias de comercialización",
    stores: "Número de tiendas físicas",
    creation: "Fecha de creación",
    direction: "Dirección",
    facturation: "Facturación anual",
    empredimientos: "Empredimientos anuales",
    products: "Productos anuales",
    model: "Ganancia (Modelo de negocio)",
    onlinestores: "Número de tiendas on line",
    onlinecreation: "Fecha de creación",
    onlinedirection: "Dirección web",
    onlinefacturation: "Facturación anual",
    onlineempredimientos: "Empredimientos anuales",
    onlineproducts: "Productos anuales",
    onlimemodel: "Ganancia (Modelo de negocio)",
    ferias: "Número de ferias",
    feriaparticipants: "Participantes en las ferias",
    space: "Espacio propio",
    representatives: "Ferias más representativas",
    budget: "Presupuesto establecido",
    other: "Ferias en otras provincias",
    feriamodel: "Ganancia (Modelo de negocio)",
    events: "Eventos al año",
    gestion: "Gestión para los eventos",
    eventlocation: "Provincias deonde se realizan los eventos",
    eventbudget: "Presupuesto establecido",
    eventmodel: "Ganancia (Modelo de negocio)",
    othercomercial: "Otros mecanismos de comercialización",
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Registros</Breadcrumb.Item>
        <Breadcrumb.Item>Tiendas</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        <br />
        <h2>
          Estado de los registros de diagnóstico para mecanismos diferenciados
        </h2>
        <Table
          columns={columns}
          dataSource={usersActive}
          loading={loadingData}
          rowKey="id"
          scroll={{ x: 1500 }}
          size="small"
          //onChange={onChange}
          exportable
          searchableProps={{
            // dataSource,
            // setDataSource: setSearchDataSource,
            inputProps: {
              placeholder: "Buscar...",
              prefix: <SearchOutlined />,
            },
          }}
          searchable
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} de ${total} registros`,
          }}
          exportableProps={{
            fields,
            fileName: "Estado de registros",
            showColumnPicker: true,
            btnProps: {
              type: "primary",
              icon: <ExportOutlined />,
              children: <span>Exportar</span>,
            },
            children: <span>Exportar</span>,
          }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No existen registros"
              />
            ),
          }}
        />
      </div>
    </div>
  );
}
