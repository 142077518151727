import React, { useState } from "react";
import { Navigate, Routes, Route, useNavigate } from "react-router-dom";
import { Layout } from "antd";
import MenuTop from "../components/Admin/MenuTop";
import MenuSider from "../components/Admin/MenuSider";
//import UseAuth from "../hooks/useAuth";

//import { getAccessToken, getRefreshToken } from "../api/auth";
import AdminHome from "../pages/Admin";
import AdminSignIn from "../pages/Admin/SignIn";
import AdminUsers from "../pages/Admin/Users";
// import AdminSectores from "../pages/Admin/Reportes/Sectores";
// import AdminDiagnostico from "../pages/Admin/Reportes/Diagnostico";
// import AdminDiagnosticoBlandas from "../pages/Admin/Reportes/DiagnosticoBlandas";
// import AdminDiagnosticoEtapas from "../pages/Admin/Reportes/DiagnosticoEtapas";
// import AdminEtapas from "../pages/Admin/Reportes/Etapas";
// import AdminCiudades from "../pages/Admin/Reportes/Ciudades";
// import AdminProvincias from "../pages/Admin/Reportes/Provincias";
// import AdminRegistros from "../pages/Admin/Reportes/Registros";
import AdminMapaTiendas from "../pages/Admin/Reportes/MapaTienda";
import AdminTiendas from "../pages/Admin/Reportes/Tiendas";
import AdminEmpleados from "../pages/Admin/Reportes/Employees";
import AdminFisicas from "../pages/Admin/Reportes/TiendasFisicas";
import AdminOnline from "../pages/Admin/Reportes/TiendasOnline";
import AdminFerias from "../pages/Admin/Reportes/Ferias";
import AdminEventos from "../pages/Admin/Reportes/Eventos";
// import AdminConocimientos from "../pages/Admin/Reportes/Conocimientos";
// import AdminOferta from "../pages/Admin/Reportes/Oferta";
// import AdminCapital from "../pages/Admin/Reportes/Capital";
// import AdminFacturacion from "../pages/Admin/Reportes/Facturacion";
//import AdminEmpleados from "../pages/Admin/Reportes/Empleo";
// import AdminInversion from "../pages/Admin/Reportes/Inversion";
// import AdminMapaUsuario from "../pages/Admin/Reportes/MapaUsuario";
// import AdminMapaEmprendimientos from "../pages/Admin/Reportes/MapaUsuarioEmprendimientos";

import "./LayoutAdmin.scss";
import useAuth from "../hooks/useAuth";

export default function LayoutAdmin(props) {
  //const { routes } = props;
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const { Header, Content, Footer } = Layout;

  const { user, isLoading } = useAuth(); //hook
  const navigate = useNavigate();

  console.log(user);
  if (!user && !isLoading) {
    return (
      <>
        <Routes>
          <Route path="/admin/login" element={<AdminSignIn />}></Route>
          {/* <Navigate to="/admin/login"></Navigate> */}
        </Routes>

        <Navigate to="/admin/login"></Navigate>
      </>
    );
  } else if (user && !isLoading && user.role !== 2) {
    return (
      <>
        <Routes>
          <Route path="/admin/login" element={<AdminSignIn />}></Route>
          {/* <Navigate to="/admin/login"></Navigate> */}
        </Routes>

        <Navigate to="/admin/login"></Navigate>
      </>
    );
  }

  if (user && !isLoading) {
    //navigate("/user");
    return (
      <Layout>
        <MenuSider menuCollapsed={menuCollapsed} />
        <Layout
          className="layout-admin"
          // style={{ marginLeft: menuCollapsed ? "80px" : "200px" }}
        >
          <Header className="layout-admin__header">
            <MenuTop
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            />
          </Header>

          <Content className="layout-admin__content">
            {/* <LoadRoutes routes={routes} /> */}
            <Routes>
              <Route path="/admin" element={<AdminHome />} />
              {/* <Route path="/admin/login" element={<AdminSignIn />} /> */}
              <Route path="/admin/tiendas" element={<AdminTiendas />} />
              <Route path="/admin/mapatiendas" element={<AdminMapaTiendas />} />
              <Route path="/admin/empleados" element={<AdminEmpleados />} />
              <Route path="/admin/users" element={<AdminUsers />} />
              <Route path="/admin/tiendasfisicas" element={<AdminFisicas />} />
              <Route path="/admin/tiendasonline" element={<AdminOnline />} />
              <Route path="/admin/ferias" element={<AdminFerias />} />
              <Route path="/admin/eventos" element={<AdminEventos />} />
            </Routes>
          </Content>
          <Footer className="layout-admin__footer">
            © Copyright CONGOPE <br></br>All Rights Reserved | Versión 1.0
          </Footer>
        </Layout>
      </Layout>
    );
  }

  return null;
}

// function LoadRoutes({ routes }) {
//   return (
//     <Routes>
//       {routes.map((route, index) => (
//         <Route
//           key={index}
//           path={route.path}
//           exact={route.exact}
//           //component={route.component}
//           element={<route.component />}
//         />
//       ))}
//     </Routes>
//   );
// }
