import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import routes from "./config/routes";
import AuthProvider from "./providers/authProvider";
import socketClient from "socket.io-client";
import { basePath } from "./api/config";
//Layout
import LayoutAdmin from "./layouts/LayoutAdmin";
import LayoutBasic from "./layouts/LayoutBasic";
import LayoutUser from "./layouts/LayoutUser";

import AdminHome from "./pages/Admin";
import AdminSignIn from "./pages/Admin/SignIn";
import AdminTiendas from "./pages/Admin/Reportes/Tiendas";
import AdminEmpleados from "./pages/Admin/Reportes/Employees";
import AdminMapaTiendas from "./pages/Admin/Reportes/MapaTienda";
import AdminFisicas from "./pages/Admin/Reportes/TiendasFisicas";
import AdminOnline from "./pages/Admin/Reportes/TiendasOnline";
import AdminFerias from "./pages/Admin/Reportes/Ferias";
import AdminEventos from "./pages/Admin/Reportes/Eventos";

import HomeUser from "./pages/User";
import PersonalUser from "./pages/User/Personal";
import EmprendimientoUser from "./pages/User/Emprendimiento";
import HaveUser from "./pages/User/Have";
import NeedUser from "./pages/User/Need";
import ChangeUser from "./pages/User/Change";
import ConnectionsUser from "./pages/User/Conexiones";
import ChatUser from "./pages/User/Chat";

import Home from "./pages/Home";
// import Contact from "../pages/Contact";

//Other
import Error404 from "./pages/Error404";

import "./App.scss";

function App() {
  var socket = socketClient(basePath, {
    transports: ["websocket", "polling", "flashsocket"],
  });

  //var socket = io('http://yourDomain:port', { transports: ['websocket', 'polling', 'flashsocket'] });

  socket.on("connection", () => {
    console.log(`I'm connected with the back-end`);
  });
  return (
    <AuthProvider>
      {/* <Router>
        <Routes>
          {routes.map((route, index) => (
            <RouterWithSubRoutes key={index} {...route} />
          ))}
        </Routes>
      </Router> */}
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Error404 />} />
          <Route element={<LayoutBasic />}>
            {/* <Route index element={<Home />} /> */}
            {/* <Route path="/*" element={<Error404 />} /> */}
            <Route path="/admin/login" element={<AdminSignIn />} />
            <Route path="/" element={<Home />} />
          </Route>
          <Route element={<LayoutAdmin />}>
            {/* <Route path="/admin/*" element={<Error404 />} /> */}
            <Route path="/admin" element={<AdminHome />} />
            <Route path="/admin/tiendas" element={<AdminTiendas />} />
            <Route path="/admin/tiendasfisicas" element={<AdminFisicas />} />
            <Route path="/admin/tiendasonline" element={<AdminOnline />} />
            <Route path="/admin/mapatiendas" element={<AdminMapaTiendas />} />
            <Route path="/admin/empleados" element={<AdminEmpleados />} />
            <Route path="/admin/ferias" element={<AdminFerias />} />
            <Route path="/admin/eventos" element={<AdminEventos />} />
          </Route>
          <Route element={<LayoutUser socket={socket} />}>
            <Route path="/user/*" element={<Error404 />} />
            <Route path="/user" socket={socket} element={<HomeUser />} />
            <Route
              path="/user/personal"
              socket={socket}
              element={<PersonalUser />}
            />
            <Route
              path="/user/emprendimiento"
              socket={socket}
              element={<EmprendimientoUser />}
            />
            <Route
              path="/user/yotengo"
              socket={socket}
              element={<HaveUser />}
            />
            <Route
              path="/user/yonecesito"
              socket={socket}
              element={<NeedUser />}
            />
            <Route
              path="/user/change"
              socket={socket}
              element={<ChangeUser />}
            />
            <Route
              path="/user/conexiones"
              element={<ConnectionsUser socket={socket} />}
            />
            <Route path="/user/chat" element={<ChatUser socket={socket} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

// function RouterWithSubRoutes(route) {
//   return (
//     <Route
//       path={route.path}
//       //exact={route.exact}
//       render={(props) => <route.component routes={route.routes} {...props} />}
//     />
//   );
// }

export default App;
