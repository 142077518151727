import React, { useState, useEffect } from "react";
import { Breadcrumb, Statistic, Card, Row, Col, PageHeader } from "antd";
import { Bar } from "@ant-design/plots";
import {
  ShoppingCartOutlined,
  PhoneOutlined,
  RightOutlined,
  UserOutlined,
  UserDeleteOutlined,
  FormOutlined,
  BulbOutlined,
  DollarOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getStoreCountApi } from "../../api/store.api";

import { getAccessTokenApi } from "../../api/auth";

import "react-leaflet-markercluster/dist/styles.min.css";

export default function Admin() {
  const token = getAccessTokenApi();
  const [total, setTotal] = useState();
  const [fisicas, setFisicas] = useState();
  const [online, setOnline] = useState();
  const [ferias, setFerias] = useState();
  const [eventos, setEvents] = useState();
  const [facturation, setFacturation] = useState();
  const [facturation1, setFacturation1] = useState();

  useEffect(() => {
    // getCountFincaApi(token).then((response) => {
    //   setCountFincas(response.count);
    // });

    // getCountEncuestaApi(token).then((response) => {
    //   setCountEncuestas(response.count);
    // });

    getStoreCountApi(token).then((response) => {
      setTotal(response.stores[0].total);
      setFisicas(response.stores[0].count);
      setOnline(response.stores[0].countonline);
      setEvents(response.stores[0].events);
      setFerias(response.stores[0].ferias);
      setFacturation1(response.stores[0].facturationonline);
      setFacturation(response.stores[0].facturation);
    });
  }, []);

  // const config = {
  //   isStack: true,
  //   xField: "count",
  //   yField: "name",
  //   seriesField: "type",
  //   label: {
  //     position: "middle",
  //     layout: [
  //       {
  //         type: "interval-adjust-position",
  //       },
  //       {
  //         type: "interval-hide-overlap",
  //       },
  //       {
  //         type: "adjust-color",
  //       },
  //     ],
  //   },
  // };
  // console.log(config);

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Panel de Control Administrador </Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        <PageHeader
          className="site-page-header header-left"
          // onBack={() => null}
          style={{
            textAlign: "left",
            justifyContent: "start",
            fontSize: "17px",
          }}
          title={
            <>
              <ToolOutlined style={{ fontSize: "17px" }} />
              <span style={{ fontSize: "17px" }}>
                {" "}
                Diagnóstico para mecanismos diferenciados
              </span>
            </>
          }
          // subTitle="This is a subtitle"
        />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={4} xl={4}>
            <Card
              title="Total registros"
              // extra={
              //   <Link to={"/admin/users"}>
              //     <RightOutlined />
              //   </Link>
              // }
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Statistic
                    title="Registros"
                    value={total}
                    precision={0}
                    valueStyle={{ color: "#3f8600" }}
                    //prefix={<UserOutlined />}
                    suffix=""
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={20} xl={20}>
            <Card
              title="Mecanismos"
              // extra={
              //   <Link to={"/encuestas"}>
              //     <RightOutlined />
              //   </Link>
              // }
            >
              <Row gutter={16}>
                <Col span={6}>
                  <Statistic
                    title="Tiendas físicas"
                    value={fisicas}
                    precision={0}
                    valueStyle={{ color: "#1890ff" }}
                    //={<BulbOutlined />}
                    suffix=" tiendas"
                  />
                </Col>
                <Col span={6}>
                  <Statistic
                    title="Tiendas on-line"
                    value={online}
                    precision={0}
                    valueStyle={{ color: "#1890ff" }}
                    //prefix={<BulbOutlined />}
                    suffix=" tiendas"
                  />
                </Col>
                <Col span={6}>
                  <Statistic
                    title="Ferias"
                    value={ferias}
                    precision={0}
                    valueStyle={{ color: "#1890ff" }}
                    //prefix={<BulbOutlined />}
                    suffix=" ferias"
                  />
                </Col>
                <Col span={6}>
                  <Statistic
                    title="Eventos"
                    value={eventos}
                    precision={0}
                    valueStyle={{ color: "#1890ff" }}
                    //prefix={<BulbOutlined />}
                    suffix=" eventos"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col span={24}>
            <Card title="Monto estimado de facturación al año">
              <Row gutter={16}>
                <Col span={12}>
                  <Statistic
                    title="Tiendas físicas"
                    value={facturation}
                    precision={0}
                    valueStyle={{
                      color: "#3f8600",
                      fontSize: "70px",
                      fontWeight: "bolder",
                    }}
                    prefix={<DollarOutlined />}
                    style={{ textAlign: "center" }}
                    //suffix=" horas"
                  />
                  <br />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Tiendas on-line"
                    value={facturation1}
                    precision={0}
                    valueStyle={{
                      color: "#3f8600",
                      fontSize: "70px",
                      fontWeight: "bolder",
                    }}
                    prefix={<DollarOutlined />}
                    style={{ textAlign: "center" }}
                    //suffix=" horas"
                  />
                  <br />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {/* <Row gutter={24}>
          <Col span={24}>
            <Card
              title="Oferta y demanda"
              // extra={
              //   <Link to={"/admin/users"}>
              //     <RightOutlined />
              //   </Link>
              // }
            >
              {!loadingH && !loadingN && (
                <Bar
                  data={[...(groupHave ?? []), ...(groupNeed ?? [])]}
                  {...config}
                />
              )}
            </Card>
          </Col>
        </Row> */}
      </div>
    </div>
  );
}
