import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";

import {
  Table,
  Button,
  Empty,
  Breadcrumb,
  Affix,
  PageHeader,
  notification,
} from "antd";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import ToolOutlined from "@ant-design/icons/ToolOutlined";

import "./Have.scss";
import { getHavesApi } from "../../../api/have.api";

import ModalUser from "../../../components/Admin/ModalUser";
import EditHaveForm from "../../../components/Home/User/Have/EditHaveForm";
import { getEntrepreneurshipByIdApi } from "../../../api/entrepreneurship.api";
import useAuth from "../../../hooks/useAuth";

export default function Have({ socket }) {
  const [haves, setHaves] = useState([]);
  const token = getAccessTokenApi();
  const [reloadHaves, setReloadHaves] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const { user } = useAuth();
  const [lastsocket, setLastsocket] = useState();

  useEffect(() => {
    socket.on("friendrequestResponse", (data) => {
      console.log(data);

      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
    socket.on("messageResponse", (data) => {
      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
  }, [socket]);

  useEffect(() => {
    getEntrepreneurshipByIdApi(token, user.id).then((response) => {
      setEntrepreneurshipData(response.entrepreneurship);

      getHavesApi(token, response.entrepreneurship.id).then((response) => {
        console.log(response.haves);
        setHaves(response.haves);
        setLoadingData(false);
      });
    });

    setReloadHaves(false);
  }, [token, reloadHaves]);

  const addHave = () => {
    if (haves.length >= 3) {
      notification["error"]({
        message: "Puedes agregar hasta 3 conocimientos que tengas",
        duration: 3,
      });
      return false;
    }
    setIsVisibleModal(true);
    setModalTitle("Agregar conocimiento que tengo");
    setmodalContent(
      <EditHaveForm
        have={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadHaves={setReloadHaves}
        buttonName="Agregar conocimiento"
        addSlag={"add"}
        entrepreneurshipData={entrepreneurshipData}
      />
    );
  };

  const editHave = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar conocimiento que tengo");
    setmodalContent(
      <EditHaveForm
        have={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadHaves={setReloadHaves}
        buttonName="Editar conocimiento"
        addSlag={"edit"}
        entrepreneurshipData={entrepreneurshipData}
      />
    );
  };

  const deleteHave = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar conocimiento que tengo");
    setmodalContent(
      <EditHaveForm
        have={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadHaves={setReloadHaves}
        buttonName="Eliminar conocimiento"
        addSlag={"delete"}
        entrepreneurshipData={entrepreneurshipData}
      />
    );
  };

  const columns = [
    {
      title: "Código",
      width: 50,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      responsive: ["md"],
    },
    {
      title: "Conocimiento",
      width: 300,
      dataIndex: ["knowledge", "name"],
      key: "knowledgeId",
    },
    {
      title: "Otros",
      width: 100,
      dataIndex: "other",
      key: "other",
      responsive: ["md"],
    },
    {
      title: "Horas entregadas",
      width: 100,
      dataIndex: "hours",
      key: "hours",
      responsive: ["md"],
    },
    {
      title: "Años de experiencia",
      width: 100,
      dataIndex: "years",
      key: "years",
      responsive: ["md"],
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 120,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editHave(record)}
          >
            <EditOutlined />
          </Button>
          <Button size="small" type="danger" onClick={() => deleteHave(record)}>
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="have">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Perfil</Breadcrumb.Item>
        <Breadcrumb.Item>Yo tengo</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addHave}>
          Agregar conocimiento que tengo
        </Button>
      </Affix>
      <PageHeader
        className="site-page-header header-left"
        // onBack={() => null}
        style={{ textAlign: "left", justifyContent: "start" }}
        title={
          <>
            <ToolOutlined style={{ fontSize: "40px" }} />
            <span>Conocimiento que tengo</span>
            <br></br>
            <span style={{ fontSize: "14px" }}>
              Seleccione máximo 3 conocimientos que tenga para intercambiarlos
              en el ecosistema
            </span>
            <br></br>
            <span style={{ fontSize: "12px", fontWeight: "normal" }}>
              Conocimientos (Administrativos, operativos, financieros,
              comunicación, marketing...)
            </span>
          </>
        }
        // subTitle="This is a subtitle"
      />
      <br />
      <Table
        columns={columns}
        dataSource={haves}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No tienes conocimientos registrados"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
