import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Menu, Tooltip } from "antd";

import HomeOutlined from "@ant-design/icons/HomeOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import LockOutlined from "@ant-design/icons/LockOutlined";
import ApiOutlined from "@ant-design/icons/ApiOutlined";
import MessageOutlined from "@ant-design/icons/MessageOutlined";

import "./MenuSiderUser.scss";

function MenuSiderUser(props) {
  const { Sider } = Layout;
  //const { SubMenu } = Menu;
  const { menuCollapsed } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [key, setKey] = useState(location.pathname);
  const [group, setGroup] = useState([]);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem("Dashboard", "/user", <HomeOutlined />),
    getItem(
      <>
        Mi perfil&nbsp;
        <Tooltip title="En este apartado podrá modificar su información personal">
          <InfoCircleOutlined />
        </Tooltip>
      </>,
      "user/perfil",
      <UserOutlined />,
      [
        getItem(
          <>
            <div className="numberCircle">1</div>
            <span className="nav-text">Datos personales</span>
          </>,
          "/user/personal"
        ),
        getItem(
          <>
            <div className="numberCircle">2</div>
            <span className="nav-text">Emprendimiento</span>
          </>,
          "/user/emprendimiento"
        ),
        getItem(
          <>
            <div className="numberCircle">3</div>
            <span className="nav-text">Yo tengo</span>
          </>,
          "/user/yotengo"
        ),
        getItem(
          <>
            <div className="numberCircle">4</div>
            <span className="nav-text">Yo necesito</span>
          </>,
          "/user/yonecesito"
        ),
      ]
    ),
    getItem("Cambiar contraseña", "/user/change", <LockOutlined />),
    getItem("Conexiones", "/user/conexiones", <ApiOutlined />),
    getItem("Conversaciones", "/user/chat", <MessageOutlined />),
  ];
  const onClick = (e) => {
    console.log("click ", e);
    setGroup(e.keyPath);
    setKey(e.key);
    navigate(e.key);
  };
  //console.log(location);
  return (
    <Sider width={256} theme="light" collapsed={menuCollapsed}>
      <Menu
        onClick={onClick}
        style={{
          marginTop: 65,
        }}
        //inlineCollapsed={menuCollapsed}
        defaultSelectedKeys={[key]}
        defaultOpenKeys={group}
        mode="inline"
        items={items}
      />
    </Sider>
  );
}

export default MenuSiderUser;
