import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Row, Col, DatePicker, Button } from "antd";
import { Bar } from "@ant-design/charts";
import { getAccessTokenApi } from "../../../api/auth";

import "./Indicadores.scss";
//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";

import { getStoreEmployeesApi } from "../../../api/store.api";

export default function Employees() {
  const token = getAccessTokenApi();
  //const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    // if (fincaId.length > 0) {
    //   if (fechaInicial && fechaFinal) {
    //     getFincasAmbitoAvgApi(token, fechaInicial, fechaFinal, fincaId).then(
    //       (response) => {
    //         setData(response.fincas);
    //       }
    //     );
    //   } else {
    //     getFincasAmbitoAvgApi(token, 0, 0, fincaId).then((response) => {
    //       setData(response.fincas);
    //     });
    //   }
    // } else {
    //   if (fechaInicial && fechaFinal) {
    //     getFincasAmbitoAvgApi(token, fechaInicial, fechaFinal, 0).then(
    //       (response) => {
    //         setData(response.fincas);
    //       }
    //     );
    //   } else {
    //     getFincasAmbitoAvgApi(token, 0, 0, 0).then((response) => {
    //       setData(response.fincas);
    //     });
    //   }
    // }
    getStoreEmployeesApi(token).then((response) => {
      //console.log(response);
      let citys1 = response.stores;
      var subtotal = 0;
      citys1.map((city) => (subtotal = subtotal + city.value));
      setTotal(subtotal);
      setData(response.stores);
    });
  }, [token]);

  const config = {
    data,
    xField: "value",
    yField: "type",
    seriesField: "type",
    //height: 800,
    legend: {
      position: "top-left",
    },
    label: {
      position: "middle",
      style: {
        fill: "#000",
        opacity: 1,
      },
    },
  };

  // function onChange(date, dateString) {
  //   if (date) {
  //     setFechaInicial(date[0].startOf("month").format("YYYY-MM-DD"));
  //     setFechaFinal(date[1].endOf("month").format("YYYY-MM-DD"));
  //   } else {
  //     setFechaInicial(null);
  //     setFechaFinal(null);
  //   }
  // }

  const ref = useRef();

  // export image
  const downloadImage = () => {
    ref.current?.downloadImage();
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Gráficas</Breadcrumb.Item>
        <Breadcrumb.Item>Encargados y personal de apoyo</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        {/* <Row gutter={16}>
          <Col span={12}>
            <RangePicker
              style={{ width: "100%" }}
              picker="month"
              onChange={onChange}
            />
          </Col>
          <Col span={12}>
            { <SelectMultiFinca setFincaId={setFincaId}></SelectMultiFinca> }
          </Col>
        </Row> */}

        <br />
        <h2>
          Número de encargados y personal de apoyo en el área de
          comercialización ({total} totales)
        </h2>

        <Bar
          {...config}
          onReady={(plot) => {
            ref.current = plot;
            setDisabled(false);
          }}
        />
        <br />
        <Button
          disabled={disabled}
          htmlType="button"
          className="btn-submit"
          onClick={downloadImage}
        >
          Guardar gráfico
        </Button>
      </div>
    </div>
  );
}
