import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";

import {
  Table,
  Button,
  Empty,
  Breadcrumb,
  Affix,
  Row,
  Col,
  Form,
  PageHeader,
  Checkbox,
  notification,
  Skeleton,
  Input,
} from "antd";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import ForkOutlined from "@ant-design/icons/ForkOutlined";
import SaveOutlined from "@ant-design/icons/SaveOutlined";
import ApiOutlined from "@ant-design/icons/ApiOutlined";

import "./Need.scss";
import { getNeedsApi } from "../../../api/need.api";

import ModalUser from "../../../components/Admin/ModalUser";
import EditNeedForm from "../../../components/Home/User/Need/EditNeedForm";
import {
  getEntrepreneurshipByIdApi,
  UpdateEntrepreneurshipNeedsApi,
} from "../../../api/entrepreneurship.api";
import useAuth from "../../../hooks/useAuth";

import SelectResourceForm from "../../../components/Admin/Resource/SelectResourceForm";
import SelectSupplierForm from "../../../components/Admin/Supplier/SelectSupplierForm";

import { AddOtherApi, getOtherByIdApi } from "../../../api/other.api";
import SelectRevenueForm from "../../../components/Admin/Revenue/SelectRevenueForm";

export default function Need({ socket }) {
  const [needs, setNeeds] = useState([]);
  const token = getAccessTokenApi();
  const [reloadNeeds, setReloadNeeds] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const { user } = useAuth();
  const [checkPartner, setCheckPartner] = useState(false);
  const [resource, setResource] = useState();
  const [supplier, setSupplier] = useState();
  const [loadings, setLoadings] = useState(false);
  const [formYonecesito] = Form.useForm();
  const [revenue4, setRevenue4] = useState();
  const [selectSupplierControl, SetSelectSupplierControl] = useState(
    <Skeleton.Input active={true} />
  );
  const [selectResourceControl, SetSelectResourceControl] = useState(
    <Skeleton.Input active={true} />
  );

  const onCheckboxPartnetChange = (e) => {
    setCheckPartner(e.target.checked);
  };

  const [lastsocket, setLastsocket] = useState();

  useEffect(() => {
    socket.on("friendrequestResponse", (data) => {
      console.log(data);

      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
    socket.on("messageResponse", (data) => {
      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
  }, [socket]);

  useEffect(() => {
    getEntrepreneurshipByIdApi(token, user.id).then((response) => {
      setEntrepreneurshipData(response.entrepreneurship);

      //setCheckPartner(true);
      const arrSuppliers = [];
      //console.log(response.entrepreneurship.suppliers);
      Object.keys(response.entrepreneurship.suppliers).forEach((key) =>
        arrSuppliers
          .push(response.entrepreneurship.suppliers[key].id)
          .toString()
      );
      var isOtherS = arrSuppliers.find((element) => {
        return element === 9;
      });
      if (isOtherS) {
        getOtherByIdApi(token, response.entrepreneurship.id, "supplier").then(
          (response) => {
            SetSelectSupplierControl(
              <SelectSupplierForm
                atributoData={arrSuppliers}
                setAtributoData={setSupplier}
                supplierValue={response.other.description}
              ></SelectSupplierForm>
            );
            console.log(response.other.description);
          }
        );
      } else {
        SetSelectSupplierControl(
          <SelectSupplierForm
            atributoData={arrSuppliers}
            setAtributoData={setSupplier}
            supplierValue={""}
          ></SelectSupplierForm>
        );
      }
      const arrResources = [];
      Object.keys(response.entrepreneurship.resources).forEach((key) =>
        arrResources
          .push(response.entrepreneurship.resources[key].id)
          .toString()
      );

      var isOther = arrResources.find((element) => {
        return element === 4;
      });
      if (isOther) {
        getOtherByIdApi(token, response.entrepreneurship.id, "resource").then(
          (response) => {
            SetSelectResourceControl(
              <SelectResourceForm
                atributoData={arrResources}
                setAtributoData={setSupplier}
                resourceValue={response.other.description}
              ></SelectResourceForm>
            );
            console.log(response.other.description);
          }
        );
      } else {
        SetSelectResourceControl(
          <SelectResourceForm
            atributoData={arrResources}
            setAtributoData={setSupplier}
            resourceValue={""}
          ></SelectResourceForm>
        );
      }

      // SetSelectResourceControl(
      //   <SelectResourceForm
      //     atributoData={arrResources}
      //     setAtributoData={setSupplier}
      //     resourceValue={""}
      //   ></SelectResourceForm>
      // );

      getNeedsApi(token, response.entrepreneurship.id).then((response) => {
        console.log(response.needs);
        setNeeds(response.needs);
        setLoadingData(false);
      });
    });

    setReloadNeeds(false);
  }, [token, reloadNeeds, user.id]);

  useEffect(() => {
    formYonecesito.setFieldsValue(entrepreneurshipData);
    console.log(entrepreneurshipData.partners ?? false);

    setCheckPartner(entrepreneurshipData.partners ?? false);
  }, [entrepreneurshipData]);

  const addNeed = () => {
    if (needs.length >= 3) {
      notification["error"]({
        message: "Puedes agregar hasta 3 conocimientos que necesites",
        duration: 3,
      });
      return false;
    }
    setIsVisibleModal(true);
    setModalTitle("Agregar conocimiento que necesito");
    setmodalContent(
      <EditNeedForm
        need={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadNeeds={setReloadNeeds}
        buttonName="Agregar conocimiento"
        addSlag={"add"}
        entrepreneurshipData={entrepreneurshipData}
      />
    );
  };

  const editNeed = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar conocimiento que necesito");
    setmodalContent(
      <EditNeedForm
        need={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadNeeds={setReloadNeeds}
        buttonName="Editar conocimiento"
        addSlag={"edit"}
        entrepreneurshipData={entrepreneurshipData}
      />
    );
  };

  const deleteNeed = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar conocimiento que necesito");
    setmodalContent(
      <EditNeedForm
        need={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadNeeds={setReloadNeeds}
        buttonName="Eliminar conocimiento"
        addSlag={"delete"}
        entrepreneurshipData={entrepreneurshipData}
      />
    );
  };

  const columns = [
    {
      title: "Código",
      width: 50,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      responsive: ["md"],
    },
    {
      title: "Conocimiento",
      width: 300,
      dataIndex: ["knowledge", "name"],
      key: "knowledgeId",
    },
    {
      title: "Otros",
      width: 100,
      dataIndex: "other",
      key: "other",
      responsive: ["md"],
    },
    {
      title: "Horas requeridas",
      width: 100,
      dataIndex: "hours",
      key: "hours",
      responsive: ["md"],
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 120,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editNeed(record)}
          >
            <EditOutlined />
          </Button>
          <Button size="small" type="danger" onClick={() => deleteNeed(record)}>
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const onFinishYonecesito = (values) => {
    setLoadings(true);

    console.log({ ...values, needpartner: values.partners });
    UpdateEntrepreneurshipNeedsApi(
      { ...values, needpartner: values.partners },
      entrepreneurshipData.id
    ).then((result) => {
      if (result.status) {
        if (values.resourceOther) {
          AddOtherApi({
            table: "resource",
            description: values.resourceOther,
            entrepreneurshipId: entrepreneurshipData.id,
          });
        }

        if (values.supplierOther) {
          AddOtherApi({
            table: "supplier",
            description: values.supplierOther,
            entrepreneurshipId: entrepreneurshipData.id,
          });
        }

        notification["success"]({
          message: "Recursos para crecer actualizados correctamente",
        });
        setLoadings(false);
      } else {
        notification["error"]({
          message: result.message,
        });
        setLoadings(false);
      }
    });
  };

  const onFinishFailYonecesito = (errorInfo) => {
    console.log("Failed:", errorInfo);
    notification["error"]({
      message: "Revisa los errores en la información ingresada",
      duration: 3,
    });
  };

  return (
    <div className="need">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Perfil</Breadcrumb.Item>
        <Breadcrumb.Item>Yo necesito</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        className="site-page-header header-left"
        // onBack={() => null}
        style={{ textAlign: "left", justifyContent: "start" }}
        // onBack={() => null}
        //style={{ textAlign: "center" }}
        title={
          <>
            <ApiOutlined style={{ fontSize: "30px" }} />
            <span> Conocimiento que necesito</span>
            <br></br>
            <span style={{ fontSize: "14px" }}>
              Seleccione máximo 3 conocimientos que necesite para
              intercambiarlos en el ecosistema.
            </span>
            <br></br>
            <span style={{ fontSize: "12px", fontWeight: "normal" }}>
              Conocimientos (Administrativos, operativos, financieros,
              comunicación, marketing...)
            </span>
          </>
        }
        // subTitle="This is a subtitle"
      />
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addNeed}>
          Agregar conocimiento que necesito
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={needs}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No tienes conocimientos registrados"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </ModalUser>
      <PageHeader
        className="site-page-header header-left"
        // onBack={() => null}
        style={{ textAlign: "left", justifyContent: "start" }}
        // onBack={() => null}
        //style={{ textAlign: "center" }}
        title={
          <>
            <ForkOutlined style={{ fontSize: "30px" }} />{" "}
            <span>Recursos para crecer</span>
          </>
        }
        // subTitle="This is a subtitle"
      />
      <Form
        form={formYonecesito}
        name="yonecesito"
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        // initialValues={{
        //   needs: [
        //     {
        //       entrepreneurshipId: entrepreneurshipData.id,
        //       knowledgeId: undefined,
        //       hours: undefined,
        //     },
        //   ],
        // }}
        onFinish={onFinishYonecesito}
        onFinishFailed={onFinishFailYonecesito}
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={4} xl={4}>
            <Form.Item
              name="partners"
              valuePropName="checked"
              label="¿Necesita socios?"
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Checkbox
                checked={checkPartner}
                onChange={onCheckboxPartnetChange}
              >
                Si busco socios
              </Checkbox>
              {/* {selectPartnersControl} */}
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={24} md={12} lg={10} xl={10}>
            {selectResourceControl}
          </Col> */}
          <Col xs={24} sm={24} md={12} lg={5} xl={5}>
            {/* <SelectSupplierForm
              atributoData={supplier}
              setAtributoData={setSupplier}
            ></SelectSupplierForm> */}
            {selectSupplierControl}
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <SelectRevenueForm
              atributoData={revenue4}
              setAtributoData={setRevenue4}
              name={"question6"}
              label={
                "¿Cuál sería el valor de inversión que necesita para hacer crecer su negocio durante este año?"
              }
            ></SelectRevenueForm>
          </Col>
          <Col xs={24} sm={24} md={12} lg={7} xl={7}>
            <Form.Item
              label="¿En qué empleará el capital?"
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name="capital"
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Describa en qué empleará el capital"
                //value={userData.name}
                maxLength={50}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <b>Recursos de apoyo</b>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              label="Organizativo"
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name="resource1"
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Describa el recurso organizativo que necesita"
                //value={userData.name}
                maxLength={50}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              label="Administrativo"
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name="resource2"
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Describa el recurso administrativo que necesita"
                //value={userData.name}
                maxLength={50}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              label="Financiero"
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name="resource3"
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Describa el recurso financiero que necesita"
                //value={userData.name}
                maxLength={50}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              label="Productivo"
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name="resource4"
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Describa el recurso productivo que necesita"
                //value={userData.name}
                maxLength={50}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              label="Comercial"
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name="resource5"
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Describa el recurso comercial que necesita"
                //value={userData.name}
                maxLength={50}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <br></br>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loadings}
                className="btnBig"
              >
                Guardar <SaveOutlined />
              </Button>
            </Form.Item>
          </Col>
          {/* <Button icon={<ArrowLeftOutlined />} onClick={() => prev()}>
              Anterior
            </Button> */}
          {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button type="deafult">
                  <ArrowLeftOutlined /> Anteior
                </Button>
              </Form.Item>
            </Col> */}
        </Row>
      </Form>
    </div>
  );
}
