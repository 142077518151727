import React, { useState, useEffect } from "react";
import {
  Result,
  Button,
  PageHeader,
  Steps,
  Input,
  Layout,
  Col,
  Form,
  Row,
  InputNumber,
  DatePicker,
  notification,
  Radio,
  Card,
  Image,
} from "antd";
import {
  ArrowRightOutlined,
  UserOutlined,
  AimOutlined,
} from "@ant-design/icons";
import Logo from "../assets/img/png/logo.png";
import "./Home.scss";
import SelectGenreForm from "../components/Admin/Genre/SelectGenreForm/SelectGenreForm";
import SelectProvinceAllForm from "../components/Admin/Provincestore/SelectProvinceForm/SelectProvinceAllForm";
//import MapPicker from "react-google-map-picker";
import Pin from "../assets/img/png/pin.png";
import { returnMailImage } from "../utils/functions";

import { apiKeyGoogle } from "../api/config";

import { AddStoreApi } from "../api/store.api";
import {} from "../api/entrepreneurship.api";

import GoogleMapReact from "google-map-react";

const { Header, Footer, Sider, Content } = Layout;

const { Step } = Steps;

const DefaultLocation = { lat: -0.5012984084076219, lng: -76.97750904233827 };
const DefaultZoom = 13;

export default function Home() {
  const [current, setCurrent] = useState(0);
  const [percent, setPercent] = useState(0);
  const [genre, setGenre] = useState();
  const [province, setProvince] = useState();

  const [storeData, setStoreData] = useState({});
  const [loadings, setLoadings] = useState(false);

  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [searchlocation, setSearchLocation] = useState(false);
  const [isProvinceLocation, setIsProvinceLocation] = useState(false);
  const [provinceLocation, setProvinceLocation] = useState({
    lat: 0.0,
    lng: 0.0,
  });

  useEffect(() => {
    if (provinceLocation.lat !== 0.0 && provinceLocation.lon !== 0.0) {
      setLocation({
        lat: provinceLocation.lat,
        lng: provinceLocation.lon,
      });
      setIsProvinceLocation(true);
    }
  }, [provinceLocation]);

  useEffect(() => {
    setTimeout(() => {
      if (!isProvinceLocation) {
        currentLocation();
      }
    }, 2000);
  }, []);

  function currentLocation() {
    setSearchLocation(true);
    var options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };

    navigator.geolocation.getCurrentPosition(success, error, options);
  }

  function success(pos) {
    var crd = pos.coords;

    setLocation({
      lat: crd.latitude,
      lng: crd.longitude,
    });
    setSearchLocation(false);
  }

  function error(err) {
    setSearchLocation(false);
    notification["success"]({
      message: `No se puede obtener la ubicación (Error: ${err.message})`,
    });
    // console.warn('ERROR(' + err.code + '): ' + err.message);
  }

  function handleChangeLocation(lat, lng) {
    setLocation({ lat: lat, lng: lng });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  function handleResetLocation() {
    setDefaultLocation({ ...DefaultLocation });
    setZoom(DefaultZoom);
  }

  const onFinish = (values) => {
    setLoadings(true);
    const storeAdd = {
      // genreId: values.genreId,
      // provincestoreId: values.provincestoreId,
      lat: location.lat,
      lon: location.lng,
      ...values,
    };
    setStoreData(storeAdd);
    console.log(storeAdd);
    AddStoreApi(storeAdd).then((result) => {
      if (result.status) {
        setStoreData(result.user);
        notification["success"]({
          message: result.message,
        });
        next();
        setLoadings(false);
      } else {
        notification["error"]({
          message: result.message,
        });
        setLoadings(false);
      }
    });

    // console.log("Success values:", values);
    // console.log("Success:", userData);
    //next();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message(errorInfo);
    notification["error"]({
      message: "Revise los errores en la información ingresada",
      duration: 3,
    });
  };

  const next = () => {
    setCurrent(current + 1);
    setPercent(0);
  };

  const prev = () => {
    setCurrent(current - 1);
    setPercent(100);
  };

  // const handleChangeYotengo = () => {
  //   formYotengo.setFieldsValue({
  //     yotengo: [],
  //   });
  // };

  // const handleChangeYonecesito = () => {
  //   formYotengo.setFieldsValue({
  //     yotengo: [],
  //   });
  // };

  const onChangeSteps = (value) => {
    console.log("onChange:", current);
    //setCurrent(value);
  };

  const steps = [
    {
      title: "Ingreso de datos",
      content: (
        <Form
          name="personal"
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {/* <PageHeader
            className="site-page-header"
            // onBack={() => null}
            style={{ textAlign: "center" }}
            title={
              <>
                <PushpinOutlined style={{ fontSize: "30px" }} />
                <br></br>
                <span>
                  Mapa de ubicación de los mecanismos de comercialización
                </span>
              </>
            }
            // subTitle="This is a subtitle"
          /> */}
          <Row gutter={24}>
            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <SelectProvinceAllForm
                    atributoData={province}
                    setAtributoData={setProvince}
                    setProvinceLocation={setProvinceLocation}
                  ></SelectProvinceAllForm>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Card
                    title={
                      <span>
                        Mapa de ubicación de los mecanismos de comercialización.
                        <br />
                        <span style={{ fontSize: "10px" }}>
                          Por favor, en el mapa presione en su ubicación para
                          mover el puntero
                        </span>
                      </span>
                    }
                    extra={
                      <Button
                        type="dashed"
                        onClick={() => currentLocation()}
                        icon={<AimOutlined />}
                        size="small"
                        className="mini"
                        loading={searchlocation}
                      >
                        buscar mi ubicación
                      </Button>
                    }
                    bodyStyle={{ padding: "0" }}
                  >
                    <div style={{ height: "350px", width: "100%" }}>
                      <GoogleMapReact
                        defaultZoom={zoom}
                        defaultCenter={defaultLocation}
                        bootstrapURLKeys={{
                          key: apiKeyGoogle,
                          language: "es",
                          region: "ec",
                          libraries: [],
                        }}
                        onClick={handleChangeLocation}
                        center={location}
                      >
                        <Image
                          width={48}
                          src={Pin}
                          lat={location.lat}
                          lng={location.lng}
                          preview={false}
                          style={{ marginTop: "-48px", marginLeft: "-24px" }}
                        />
                      </GoogleMapReact>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
              <PageHeader
                className="site-page-header left orange"
                // onBack={() => null}
                style={{ textAlign: "left" }}
                title={
                  <span style={{ color: "white" }}>Datos del encargado</span>
                }
                // subTitle="This is a subtitle"
              />
              <Row gutter={24}>
                {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    label="Cédula"
                    wrapperCol={{ span: 24 }}
                    labelCol={{ span: 24 }}
                    name="ci"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese su cédula de identidad",
                      },
                      {
                        type: "string",
                        min: 10,
                        message: "Su cédula de identidad no es correcta",
                      },
                    ]}
                  >
                    <Input
                      //prefix={<UserOutlined />}
                      placeholder="Cédula de identidad"
                      //value={userData.name}
                      maxLength={10}
                      onChange={
                        (e) => {}
                        //setUserData({ ...userData, name: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col> */}
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label="Nombres del encargado"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese los nombres del encargado",
                      },
                    ]}
                    wrapperCol={{ span: 24 }}
                    labelCol={{ span: 24 }}
                  >
                    <Input
                      //prefix={<UserOutlined />}
                      placeholder="Nombres y apellidos del encargado"
                      maxLength={150}
                      //value={userData.name}
                      onChange={
                        (e) => {}
                        //setUserData({ ...userData, name: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label="Cargo que ocupa en el GAD Provincial"
                    name="cargo"
                    rules={[
                      {
                        required: true,
                        message:
                          "Ingrese el cargo que ocupa en el GAD Provincial",
                      },
                    ]}
                    wrapperCol={{ span: 24 }}
                    labelCol={{ span: 24 }}
                  >
                    <Input
                      //prefix={<UserOutlined />}
                      placeholder="Cargo que ocupa en el GAD Provincial"
                      //value={userData.name2}
                      maxLength={150}
                      onChange={
                        (e) => {}
                        //setUserData({ ...userData, name2: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Form.Item
                    label="Teléfono del encargado"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese el teléfono del encargado",
                      },
                    ]}
                    wrapperCol={{ span: 24 }}
                    labelCol={{ span: 24 }}
                  >
                    <Input
                      //prefix={<UserOutlined />}
                      placeholder="Teléfono/celular"
                      //value={userData.name2}
                      maxLength={10}
                      onChange={
                        (e) => {}
                        //setUserData({ ...userData, name2: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                  <Form.Item
                    label="Correo"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese su correo electrónico",
                      },
                      {
                        type: "email",
                        message: "El correo ingresado no es correcto",
                      },
                    ]}
                    wrapperCol={{ span: 24 }}
                    labelCol={{ span: 24 }}
                  >
                    <Input
                      //prefix={<UserOutlined />}
                      placeholder="Correo electrónico"
                      //value={userData.name}
                      maxLength={50}
                      onChange={
                        (e) => {}
                        //setUserData({ ...userData, name: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <SelectGenreForm
                    atributoData={genre}
                    setAtributoData={setGenre}
                  ></SelectGenreForm>
                </Col>
              </Row>
            </Col>
          </Row>
          <PageHeader
            className="site-page-header left orange"
            // onBack={() => null}
            style={{ textAlign: "left" }}
            title={
              <span style={{ color: "white" }}>
                Mecanismos de comercialización
              </span>
            }
            // subTitle="This is a subtitle"
          />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item
                label="Número de encargados y personal de apoyo en el área de comercialización"
                name="employees"
                rules={[
                  {
                    required: true,
                    message: "Ingrese el número de encargados",
                  },
                ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <InputNumber
                  //prefix={<UserOutlined />}
                  placeholder="Número de encargados"
                  maxLength={4}
                  //value={userData.name}
                  //defaultValue={1999}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <Form.Item
                label="¿Qué estrategias de comercialización manejan actualmente?"
                name="strategies"
                // rules={[
                //   {
                //     required: true,
                //     message: "Ingrese las estrategias",
                //   },
                // ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  multiline={true}
                  placeholder="Estrategias"
                  maxLength={1000}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <PageHeader
            className="site-page-header left orange"
            // onBack={() => null}
            style={{ textAlign: "left" }}
            title={<span style={{ color: "white" }}>Tienda física</span>}
            // subTitle="This is a subtitle"
          />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="stores"
                label="Número de tiendas físicas"
                rules={[
                  {
                    required: true,
                    message: "Seleccione el número de tiendas físicas",
                  },
                ]}
              >
                {/* <Input placeholder="Años de experiencia" /> */}
                {/* <InputNumber
                              placeholder="Años de experiencia"
                              maxLength={4}
                            /> */}
                <Radio.Group>
                  <Radio value={0}>Ninguna</Radio>
                  <Radio value={1}>1</Radio>
                  <Radio value={2}>2</Radio>
                  <Radio value={3}>3</Radio>
                  <Radio value={5}>5</Radio>
                  <Radio value={7}>7 o más</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Form.Item
                label="Fecha de creación"
                name="creation"
                // rules={[
                //   {
                //     required: true,
                //     message: "Seleccione la fecha de creación",
                //   },
                // ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Dirección de la tienda física"
                name="direction"
                // rules={[
                //   {
                //     required: true,
                //     message: "Ingrese la dirección",
                //   },
                // ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  multiline={true}
                  placeholder="Dirección"
                  maxLength={1000}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                label="Monto estimado de facturación al año"
                name="facturation"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <InputNumber placeholder="Monto estimado" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                label="Número de emprendimientos que manejan al año"
                name="empredimientos"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <InputNumber
                  maxLength={4}
                  placeholder="Número de emprendimientos"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                label="Número de productos que manejan al año"
                name="products"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <InputNumber maxLength={4} placeholder="Número de productos" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="¿Cuál es la ganancia que obtienen por cada tienda? (Modelo de negocio)"
                name="model"
                // rules={[
                //   {
                //     required: true,
                //     message: "Ingrese la dirección",
                //   },
                // ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  multiline={true}
                  placeholder="(Modelo de negocio)"
                  maxLength={1000}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <PageHeader
            className="site-page-header left orange"
            // onBack={() => null}
            style={{ textAlign: "left" }}
            title={<span style={{ color: "white" }}>Tienda on line</span>}
            // subTitle="This is a subtitle"
          />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="onlinestores"
                label="Número de tiendas on line"
                rules={[
                  {
                    required: true,
                    message: "Seleccione el número de tiendas on line",
                  },
                ]}
              >
                {/* <Input placeholder="Años de experiencia" /> */}
                {/* <InputNumber
                              placeholder="Años de experiencia"
                              maxLength={4}
                            /> */}
                <Radio.Group>
                  <Radio value={0}>Ninguna</Radio>
                  <Radio value={1}>1</Radio>
                  <Radio value={2}>2</Radio>
                  <Radio value={3}>3</Radio>
                  <Radio value={5}>5</Radio>
                  <Radio value={7}>7 o más</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Form.Item
                label="Fecha de creación"
                name="onlinecreation"
                // rules={[
                //   {
                //     required: true,
                //     message: "Seleccione la fecha de creación",
                //   },
                // ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Dirección electrónica de la tienda"
                name="onlinedirection"
                // rules={[
                //   {
                //     required: true,
                //     message: "Ingrese la dirección web",
                //   },
                // ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  multiline={true}
                  placeholder="Dirección web"
                  maxLength={1000}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                label="Monto estimado de facturación al año"
                name="onlinefacturation"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <InputNumber placeholder="Monto estimado" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                label="Número de emprendimientos que manejan al año"
                name="onlineempredimientos"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <InputNumber
                  maxLength={4}
                  placeholder="Número de emprendimientos"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                label="Número de productos que manejan al año"
                name="onlineproducts"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <InputNumber maxLength={4} placeholder="Número de productos" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="¿Cuál es la ganancia que obtienen por cada tienda? (Modelo de negocio)"
                name="onlimemodel"
                // rules={[
                //   {
                //     required: true,
                //     message: "Ingrese la dirección",
                //   },
                // ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  multiline={true}
                  placeholder="(Modelo de negocio)"
                  maxLength={1000}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <PageHeader
            className="site-page-header left orange"
            // onBack={() => null}
            style={{ textAlign: "left" }}
            title={<span style={{ color: "white" }}>Feria</span>}
            // subTitle="This is a subtitle"
          />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="ferias"
                label="Número de ferias promedio que se organizan al año"
                rules={[
                  {
                    required: true,
                    message: "Seleccione el número de ferias promedio",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={0}>Ninguna</Radio>
                  <Radio value={1}>1</Radio>
                  <Radio value={2}>2</Radio>
                  <Radio value={3}>3</Radio>
                  <Radio value={5}>5</Radio>
                  <Radio value={7}>7 o más</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                label="Número de participantes por feria"
                name="feriaparticipants"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Ingrese el número de participantes",
                //   },
                // ]}
              >
                <InputNumber
                  maxLength={4}
                  placeholder="Número de participantes"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="space"
                label="Tiene espacio propio para la feria"
                // rules={[
                //   {
                //     required: true,
                //     message: "Seleccione si o no",
                //   },
                // ]}
              >
                <Radio.Group>
                  <Radio value={"Si"}>Si</Radio>
                  <Radio value={"No"}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="¿Cuáles son las ferias más representativas?"
                name="representatives"
                // rules={[
                //   {
                //     required: true,
                //     message: "Ingrese la dirección",
                //   },
                // ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  multiline={true}
                  placeholder="Ferias más representativas"
                  maxLength={1000}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="budget"
                label="¿Tienen presupuesto establecido para estas ferias? "
                // rules={[
                //   {
                //     required: true,
                //     message: "Seleccione si o no",
                //   },
                // ]}
              >
                <Radio.Group>
                  <Radio value={"Si"}>Si</Radio>
                  <Radio value={"No"}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="other"
                label="¿Participan en ferias adicionales en otras provincias?"
                // rules={[
                //   {
                //     required: true,
                //     message: "Seleccione si o no",
                //   },
                // ]}
              >
                <Radio.Group>
                  <Radio value={"Si"}>Si</Radio>
                  <Radio value={"No"}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="¿Cuál es la ganancia que obtienen por cada feria? (Modelo de negocio)"
                name="feriamodel"
                // rules={[
                //   {
                //     required: true,
                //     message: "Ingrese la dirección",
                //   },
                // ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  multiline={true}
                  placeholder="(Modelo de negocio)"
                  maxLength={1000}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <PageHeader
            className="site-page-header left orange"
            // onBack={() => null}
            style={{ textAlign: "left" }}
            title={
              <span style={{ color: "white" }}>
                Eventos de conexiones de mercado
              </span>
            }
            // subTitle="This is a subtitle"
          />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="events"
                label="Número de eventos promedio que se organizan al año"
                rules={[
                  {
                    required: true,
                    message: "Seleccione el número de eventos promedio",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={0}>Ninguna</Radio>
                  <Radio value={1}>1</Radio>
                  <Radio value={2}>2</Radio>
                  <Radio value={3}>3</Radio>
                  <Radio value={5}>5</Radio>
                  <Radio value={7}>7 o más</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="gestion"
                label="¿Cómo se realiza la gestión para los eventos?"
                // rules={[
                //   {
                //     required: true,
                //     message: "Seleccione una opción",
                //   },
                // ]}
              >
                <Radio.Group>
                  <Radio value={"Gestión propia"}>Gestión propia</Radio>
                  <Radio value={"Con colaboraciones"}>Con colaboraciones</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="¿Cuáles son las principales provincias con las que se realizan los eventos?"
                name="eventlocation"
                // rules={[
                //   {
                //     required: true,
                //     message: "Ingrese las principales provincias",
                //   },
                // ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  multiline={true}
                  placeholder="Principales provincias"
                  maxLength={1000}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="eventbudget"
                label="¿Tienen presupuesto establecido para estos eventos?"
                // rules={[
                //   {
                //     required: true,
                //     message: "Seleccione si o no",
                //   },
                // ]}
              >
                <Radio.Group>
                  <Radio value={"Si"}>Si</Radio>
                  <Radio value={"No"}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="¿Cuál es la ganancia que obtienen por cada evento? (Modelo de negocio)"
                name="eventmodel"
                // rules={[
                //   {
                //     required: true,
                //     message: "Ingrese la dirección",
                //   },
                // ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  multiline={true}
                  placeholder="(Modelo de negocio)"
                  maxLength={1000}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <PageHeader
            className="site-page-header left orange"
            // onBack={() => null}
            style={{ textAlign: "left" }}
            title={
              <span style={{ color: "white" }}>
                Otros mecanismos de comercialización{" "}
              </span>
            }
            // subTitle="This is a subtitle"
          />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="Ingrese si tiene otros mecanismos de comercialización "
                name="othercomercial"
                // rules={[
                //   {
                //     required: true,
                //     message: "Ingrese la dirección",
                //   },
                // ]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
              >
                <Input
                  //prefix={<UserOutlined />}
                  multiline={true}
                  placeholder="Otros mecanismos"
                  maxLength={1000}
                  //value={userData.name}
                  onChange={
                    (e) => {}
                    //setUserData({ ...userData, name: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <br></br>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  loading={loadings}
                  className={"btnBig"}
                >
                  Registrar <ArrowRightOutlined />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ),
      icon: <UserOutlined />,
    },
    {
      title: "Guardado",
      content: (
        <Result
          status="success"
          title={`¡Su registro se ha completado exitosamente!`}
          subTitle="Gracias por completar el cuestionario de Diagnóstico para mecanismos diferenciados"
          // extra={[
          //   <Button
          //     type="primary"
          //     key="console"
          //     style={{ height: "60px", width: "180px" }}
          //     onClick={() => navigate(`/admin/login`)}
          //     className={"btnBig"}
          //   >
          //     Ir a mi cuenta
          //   </Button>,
          //   // <Button key="buy">Buy Again</Button>,
          // ]}
        />
      ),
      icon: <UserOutlined />,
    },
  ];

  return (
    <Layout className="home">
      <Header className="home__header">
        <Row gutter={24}>
          <Col xs={6} sm={6} md={4} lg={4} xl={4}>
            <img className="home__header__img" alt="congope" src={Logo}></img>
          </Col>
          <Col xs={8} sm={10} md={12} lg={16} xl={16}></Col>
          <Col
            xs={10}
            sm={8}
            md={8}
            lg={4}
            xl={4}
            style={{ textAlign: "rigth" }}
          >
            {/* <Button
              type="link"
              style={{ marginTop: "19px", color: "black", fontSize: "19px" }}
              onClick={() => navigate(`/admin/login`)}
            >
              Ingresar <LoginOutlined />
            </Button> */}
          </Col>
        </Row>
      </Header>
      <Content className="home__content">
        <div className="home__content__steps">
          <PageHeader
            className="site-page-header"
            style={{ textAlign: "center" }}
            title={
              <span style={{ fontWeight: "bold", fontSize: "32px" }}>
                Diagnóstico para mecanismos diferenciados
              </span>
            }
            // subTitle="This is a subtitle"
          />
          <br />
          <Steps current={current} onChange={onChangeSteps}>
            {steps.map((item) => (
              <Step key={item.title} disabled={true} title={item.title} />
            ))}
          </Steps>
          <br></br>
          <div className="steps-content">{steps[current].content}</div>
          <br></br>
        </div>
      </Content>
    </Layout>
  );
}
