import React, { useState, useEffect } from "react";
import { Select, Form } from "antd";

import { getProvincestoresApi } from "../../../../api/provincestore.api";

import "./SelectProvinceForm.scss";

const { Option } = Select;

export default function SelectProvinceAllForm(props) {
  const { atributoData, setAtributoData, setProvinceLocation } = props;
  const [provinceData, setProvinceData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [provinceId, setProvinceId] = useState();

  useEffect(() => {
    getProvincestoresApi().then((response) => {
      setProvinceData(response.provincestores);
      //console.log(response.provinces);
      setProvinceId(atributoData);
      //console.log(atributoData);
    });
    setLoadingData(false);
  }, [loadingData]);

  const handleAtributoChange = (value, item) => {
    setProvinceId(value);
    setAtributoData(value ?? 0);
    setProvinceLocation({ lat: item.lat, lon: item.lon });
  };

  return (
    <div className="select-province-form">
      <Form.Item
        label="Provincia"
        name="provincestoreId"
        rules={[{ required: true, message: "Seleccione una provincia" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        //value={provinceId}
      >
        <Select
          placeholder="Seleccione la provincia"
          value={provinceId}
          onChange={handleAtributoChange}
          showSearch
          optionFilterProp="children"
          loading={loadingData}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          //disabled={disabled}
          allowClear={true}
        >
          {provinceData && provinceData.map
            ? provinceData.map((item) => (
                <Option
                  key={item.id}
                  value={item.id}
                  lat={item.lat}
                  lon={item.lon}
                >
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
    </div>
  );
}
