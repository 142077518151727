import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Form, Card, Row, Col, Select } from "antd";
import { getAccessTokenApi } from "../../../api/auth";
//import Pin from "../../../assets/img/png/pin.png";
//import { apiKeyGoogle } from "../../../api/config";
import { getStoreMapApi } from "../../../api/store.api";
//import GoogleMapReact from "google-map-react";
//import styled from "styled-components";
//import NewMarker from "../../../components/Common/newmarker";
//import Map from "../../../components/Common/Map";
import MapContainer from "../../../components/Common/MapContainerTienda";

import "./Indicadores.scss";
// import SelectSectorAllForm from "../../../components/Admin/Sector/SelectSectorForm/SelectSectorAllForm";
// import SelectStageAllForm from "../../../components/Admin/Stage/SelectStageForm/SelectStageAllForm";
//import { LoadScript } from "@react-google-maps/api";
// import {
//   GoogleMap,
//   MarkerClusterer,
//   useJsApiLoader,
// } from "@react-google-maps/api";

//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";

//import { getNeedGroupApi } from "../../../api/need.api";
//import Pin from "../../../assets/img/png/pin.png";
const DefaultLocation = { lat: -0.5012984084076219, lng: -76.97750904233827 };
// const DefaultZoom = 12;
// const {
//   MarkerClusterer,
// } = require("react-google-maps/lib/components/addons/MarkerClusterer");
const { Option } = Select;
export default function MapaTienda() {
  const token = getAccessTokenApi();
  //const { RangePicker } = DatePicker;
  //const [data, setData] = useState([]);
  //const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  //const [location, setLocation] = useState(defaultLocation);
  //const [zoom, setZoom] = useState(DefaultZoom);
  //const [searchlocation, setSearchLocation] = useState(false);
  //const [users, setUsers] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [locations, setlocations] = useState([]);
  const [tipo, setTipo] = useState("5");
  //const [heatmapVisible, sethHeatmapVisible] = useState(false);
  // const [sector, setSector] = useState(0);
  // const [stage, setStage] = useState(0);
  // const [markers, setMarkers] = useState();
  // const [markers1, setMarkers1] = useState();
  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: apiKeyGoogle,
  //   libraries: ["geometry", "drawing", "places", "visualization"],
  // });

  //const [map, setMap] = useState(null);
  // const [info, setInfo] = useState({
  //   key: 0,
  //   name: "",
  //   lastname: "",
  //   email: "",
  //   emprendimiento: "",
  //   perfil: "",
  //   ciudad: "",
  // });
  // const [showinfo, setShowinfo] = useState(false);

  //const google = window.google;
  // const {
  //   InfoBox,
  // } = require("react-google-maps/lib/components/addons/InfoBox");
  //const ref = useRef();
  //const clustererRef = useRef();
  // var CMap = withScriptjs(
  //   withGoogleMap((props) => (
  //     <GoogleMap
  //       defaultZoom={DefaultZoom}
  //       defaultCenter={DefaultLocation}
  //       //libraries={["visualization"]}
  //     >
  //       {/* {props.children} */}
  //       <MarkerClusterer
  //         onLoad={(clusterer) => (clustererRef.current = clusterer)}
  //       >
  //         {props.markers}
  //       </MarkerClusterer>
  //     </GoogleMap>
  //   ))
  // );

  const handleAtributoChange = (value) => {
    setTipo(value);
  };

  useEffect(() => {
    //setLoadingData(true);
    getStoreMapApi(token, tipo ?? "5").then((response) => {
      // console.log(stage);
      // console.log(sector);
      console.log(response.stores);
      //setUsers(response.users);

      const data1 = [];
      let stores1 = response.stores ?? [];
      stores1.map((store) =>
        data1.push({
          location: {
            lat: store.provincestore.lat,
            lng: store.provincestore.lon,
          },
          name: store.name,
          email: store.email,
          phone: store.phone,
          id: store.id,
        })
      );
      setlocations(data1);

      setLoadingData(false);
    });
  }, [token, tipo]);

  // function mapFitBounds() {
  //   // console.log("mapFitBounds:map> ", map);
  //   if (!map || !users) return;

  //   const bounds = new window.google.maps.LatLngBounds();
  //   users.map((loc) => {
  //     bounds.extend(new window.google.maps.LatLng(loc.lat, loc.lon));
  //   });

  //   map.fitBounds(bounds);
  // }

  // React.useEffect(() => {
  //   if (map) {
  //     // map.panTo(...)
  //     mapFitBounds();
  //   }
  // }, [map]);

  // const toggleHeatMap = () => {
  //   sethHeatmapVisible(!heatmapVisible);
  //   // if (ref.current !== undefined) {
  //   //   ref.current.heatmap.setMap(!heatmapVisible ? ref.current.map_ : null);
  //   // }
  // };

  // const config = {
  //   heatmap: {
  //     positions: heatmapVisible,
  //     options: { radius: 40, opacity: 0.8 },
  //   },
  // };

  // const onLoad = React.useCallback(function callback(map) {
  //   // const bounds = new window.google.maps.LatLngBounds(defaultLocation);
  //   // //map.fitBounds(bounds);
  //   // map.setZoom(zoom);
  //   setMap(map);
  // }, []);

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null);
  // }, []);

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Mapa</Breadcrumb.Item>
        <Breadcrumb.Item>
          Diagnóstico para mecanismos diferenciados
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        <Form name="filters" initialValues={{ tipo: "5" }} autoComplete="off">
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item
                label="Mecanismo de comercialización"
                name="tipo"
                //rules={[{ required: true, message: "Seleccione un sector" }]}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                //style={other && { marginBottom: "5px" }}
                //style={{ marginBottom: "24px" }}
                //initialValue={atributoData}
              >
                <Select
                  placeholder="Selecciona el mecanismo"
                  //value={tipo}
                  onChange={handleAtributoChange}
                  //mode="multiple"
                  //disabled={disabled}
                  allowClear={true}
                  deafultValue="5"
                  options={[
                    { value: "5", label: "Todos" },
                    { value: "1", label: "Tienda física" },
                    { value: "2", label: "Tienda on line" },
                    { value: "3", label: "Feria" },
                    { value: "4", label: "Evento" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Card
          title={
            <span>
              Mapa de todo el ecosistema del diagnóstico para mecanismos
              diferenciados
            </span>
          }
          bodyStyle={{ padding: "0" }}
        >
          {!loadingData ? (
            <MapContainer
              defaultCenter={DefaultLocation}
              locations={locations}
            />
          ) : (
            <></>
          )}
        </Card>
      </div>
    </div>
  );
}
