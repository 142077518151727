import { basePath } from "./config";

export async function getResourcesApi() {
  const url = `${basePath}/resources`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      //Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export function UpdateResourceApi(token, resource, resourceId) {
  const url = `${basePath}/resource/update/${resourceId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(resource),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function AddResourceApi(token, resource) {
  const url = `${basePath}/resource/create`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(resource),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function DeleteResourceApi(token, resourceId) {
  const url = `${basePath}/resource/delete/${resourceId}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
