import React, { useState, useEffect } from "react";
import { Select, Form, Input } from "antd";

import { getSectorsApi } from "../../../../api/sector.api";

import "./SelectSectorForm.scss";

const { Option } = Select;

export default function SelectSectorForm(props) {
  const { atributoData, setAtributoData, deafultValue } = props;
  const [sectorData, setSectorData] = useState({});
  //const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [sectorId, setSectorId] = useState();
  const [other, setOther] = useState(false);

  useEffect(() => {
    getSectorsApi().then((response) => {
      setSectorData(response.sectors);
      //setSectorId(atributoData);
    });
    setLoadingData(false);
  }, [loadingData]);

  const handleAtributoChange = (value) => {
    setSectorId(value);
    setAtributoData(value);
    var isOther = value.find((element) => {
      return element === 18;
    });
    if (isOther) {
      setOther(true);
    } else {
      setOther(false);
    }
  };

  useEffect(() => {
    //console.log(values);
    if (atributoData) {
      var isOther = atributoData.find((element) => {
        return element === 18;
      });
      if (isOther) {
        setOther(true);
      } else {
        setOther(false);
      }
    } else {
      setOther(false);
    }
  }, [atributoData]);

  return (
    <div className="select-sector-form">
      <Form.Item
        label="Sector al que pertenece"
        name="sector"
        rules={[{ required: true, message: "Seleccione un sector" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        //style={other && { marginBottom: "5px" }}
        style={{ marginBottom: other ? "5px" : "24px" }}
        initialValue={atributoData}
      >
        <Select
          placeholder="Seleccione el sector"
          value={sectorId}
          onChange={handleAtributoChange}
          mode="multiple"
          //disabled={disabled}
        >
          {sectorData && sectorData.map
            ? sectorData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
      {other && (
        <Form.Item
          //label="Otros"
          name="sectorOther"
          rules={[{ required: true, message: "Ingrese el sector" }]}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          initialValue={deafultValue}
        >
          <Input
            //prefix={<UserOutlined />}
            placeholder="Sector"
            //value={userData.name}
            maxLength={200}
          />
        </Form.Item>
      )}
    </div>
  );
}
