import React, { useState, useEffect, useRef } from "react";
import { getAccessTokenApi } from "../../../api/auth";

import {
  Card,
  Row,
  Breadcrumb,
  notification,
  Col,
  // Avatar,
  Empty,
} from "antd";
// import {
//   SolutionOutlined,
//   UserAddOutlined,
//   ClockCircleOutlined,
//   CheckOutlined,
// } from "@ant-design/icons";
import "react-chat-elements/dist/main.css";
import {
  ChatList,
  Input,
  Button,
  Avatar,
  Navbar,
  MessageList,
} from "react-chat-elements";

import "./Chat.scss";

import useAuth from "../../../hooks/useAuth";
import { getFriendsApi, getFriendsUserApi } from "../../../api/friend.api";
//import { UpdateFriendApi } from "../../../api/friend.api";
// import noAvatar from "../../../assets/img/png/no-avatar.png";
// import ApiOutlined from "@ant-design/icons/ApiOutlined";
// import UsergroupAddOutlined from "@ant-design/icons/UsergroupAddOutlined";
import {
  AddMessageApi,
  getMessagesApi,
  UpdateMessageApi,
} from "../../../api/message.api";
import moment from "moment";

const Chat = ({ socket }) => {
  const token = getAccessTokenApi();
  const [loadings, setLoadings] = useState(true);
  const [potentials, setPotentials] = useState([]);
  const { user, isLoading } = useAuth();
  const [initLoading, setInitLoading] = useState(true);
  const [initLoadingNeed, setInitLoadingNeed] = useState(true);
  const [offset, setOffset] = useState(0);
  const [more, setMore] = useState(true);
  const [moreNeed, setMoreNeed] = useState(true);
  const [loadingFriend, setLoadingFriend] = useState(false);
  const [messages, setMessages] = useState([]);
  const [friend, setFriend] = useState();
  const [loadingsMessages, setLoadingsMessages] = useState(false);
  const [sendMessages, setSendMessages] = useState(false);
  const [message, setMessage] = useState("");
  const [lastsocket, setLastsocket] = useState();
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    socket.off("friendrequestResponse");
    socket.on("friendrequestResponse", (data) => {
      console.log(data);

      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
          duration: 0,
        });
      }
    });
    socket.off("messageResponse");
    socket.on("messageResponse", (data) => {
      console.log(data);
      console.log(friend);
      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);

        if (friend && friend.friendId === data.friendId) {
          setMessages((messages) => [...messages, data.message]);
          setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
          }, 200);
          UpdateMessageApi(token, data.friendId, data.user).then((response) => {
            //setLoadings(true);
          });
        } else {
          notification["info"]({
            message: data.content,
            duration: 10,
          });
          setLoadings(true);
        }
      }
    });
  }, [socket, friend]);

  useEffect(() => {
    if (loadings) {
      getFriendsApi(token, user.id).then((response) => {
        console.log(response);
        getFriendsUserApi(token, user.id).then((response1) => {
          //console.log(response1);

          const data1 = [];
          let friends = response.friends ?? [];
          let friends1 = response1.friends ?? [];
          friends.map((item) =>
            //console.log(item);
            data1.push({
              id: item.users1[0].id,
              key: item.users1[0].id,
              avatar: "../no-avatar.png",
              alt: item.users1[0].name,
              title: `${item.users1[0].name} ${item.users1[0].lastname}`,
              subtitle:
                item.messages.length > 0 ? item.messages[0].message : "",
              dateString:
                item.messages.length > 0
                  ? moment(item.messages[0].date).fromNow()
                  : "-",
              //date: item.messages[0].date,
              unread: item.noread,
              friendId: item.id,
            })
          );
          friends1.map((item) =>
            //console.log(item);
            data1.push({
              id: item.users2[0].id,
              key: item.users2[0].id,
              avatar: "../no-avatar.png",
              alt: item.users2[0].name,
              title: `${item.users2[0].name} ${item.users2[0].lastname}`,
              subtitle:
                item.messages.length > 0 ? item.messages[0].message : "",
              //dateString: "-",
              //date: item.messages[0].date,
              dateString:
                item.messages.length > 0
                  ? moment(item.messages[0].date).fromNow()
                  : "-",
              unread: item.noread,
              friendId: item.id,
            })
          );
          //console.log(data1);
          setPotentials(data1);
          //setPotentials(response.users[0]?.friends);
          setLoadings(false);
          setInitLoading(false);
        });
      });
    }
    // });
  }, [token, loadings]);

  const onClick = (item) => {
    console.log(item);
    setFriend(item);
    setLoadingsMessages(true);
    getMessagesApi(token, item.friendId).then((response) => {
      //console.log(response);
      const data1 = [];
      let messages = response.messages ?? [];
      messages.map((item) =>
        data1.push({
          position: user.id === item.user.id ? "right" : "left",
          type: "text",
          title: item.user.name,
          text: item.message,
          date: item.date,
          dateString: moment(item.date).fromNow(),
          //status: "sent",
        })
      );
      setLoadingsMessages(false);
      setMessages(data1);

      UpdateMessageApi(token, item.friendId, item.id).then((response) => {
        setLoadings(true);
      });

      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    });
  };

  const sendMessage = () => {
    if (message && message.length > 0) {
      setSendMessages(true);
      AddMessageApi(token, {
        message: message,
        status: 0,
        friendId: friend.friendId,
        userId: user.id,
        date: moment(),
        //dateString: moment().fromNow(),
      }).then((response) => {
        console.log(response);

        setMessages((messages) => [
          ...messages,
          {
            position: "right",
            type: "text",
            title: user.name,
            text: message,
            date: moment(),
            dateString: moment().fromNow(),
          },
        ]);

        socket.emit("message", {
          user: user.id,
          friendId: friend.friendId,
          destiny: friend.id,
          content: `${user.name} te ha enviado un nuevo mensaje`,
          message: {
            position: "left",
            type: "text",
            title: user.name,
            text: message,
            date: moment(),
            dateString: moment().fromNow(),
          },
        });

        setSendMessages(false);
        setMessage(null);
        inputRef.current.value = null;
        //inputRef.clear();
        setTimeout(() => {
          messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 200);
        //setMessages(response.messages);
      });
    }
  };

  return (
    <div>
      <div className="Conexiones">
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Emprendedor</Breadcrumb.Item>
          <Breadcrumb.Item>Conversaciones</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="site-statistic-demo-card">
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card title={<span>Mis conversaciones:</span>}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                  <ChatList
                    className="chat-list"
                    dataSource={potentials}
                    onClick={onClick}
                    loading={loadings}
                  />
                  {/* <List
                    size="small"
                    //itemLayout="horizontal"
                    itemLayout="vertical"
                    dataSource={potentials}
                    loading={loadings}
                    bordered
                    //loadMore={loadMore}
                    renderItem={(item) => (
                      <List.Item extra={item.messages.length > 0 && "17:27"}>
                        <List.Item.Meta
                          avatar={<Avatar src={noAvatar} />}
                          title={
                            <>
                              {item.name.toUpperCase() +
                                " " +
                                item.lastname.toUpperCase()}
                            </>
                          }
                          description={
                            item.messages.length > 0 && (
                              <>
                                <CheckOutlined style={{ fontSize: "10px" }} />
                                <CheckOutlined
                                  style={{ fontSize: "10px" }}
                                />{" "}
                                "Buen dia"
                              </>
                            )
                          }
                        />
                      </List.Item>
                    )}
                  /> */}
                </Col>
                <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                  <div
                    style={{
                      minHeight: "400px",
                      backgroundColor: "rgb(246, 246, 246)",
                      padding: "10px 10px 80px 10px",
                    }}
                  >
                    {!friend && (
                      <div>
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description="Seleccione un emprendedor para empezar a comunicarse!"
                        />
                      </div>
                    )}
                    {friend && (
                      <Navbar
                        left=<div>
                          <Avatar
                            src="../no-avatar.png"
                            alt="avatar"
                            size="small"
                            type="circle"
                          />
                          <div
                            style={{
                              marginTop: "6px",
                            }}
                          >
                            {friend ? friend.title : ""}
                          </div>
                        </div>
                        //right=
                        type="light"
                      />
                    )}
                    {friend && (
                      <div
                        style={{
                          height: "350px",
                          padding: "10px",
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        <MessageList
                          className="message-list"
                          lockable={true}
                          toBottomHeight={"100%"}
                          dataSource={messages}
                        />
                        <div ref={messagesEndRef} />
                      </div>
                    )}

                    {friend && (
                      <div
                        style={{
                          position: "absolute",
                          bottom: "10px",
                          right: "20px",
                          width: "calc(100% - 40px)",
                        }}
                      >
                        <Input
                          placeholder="Ingresa el mensaje aquí..."
                          multiline={false}
                          id="message"
                          name="message"
                          referance={inputRef}
                          onChange={handleChange}
                          //value={message}
                          rightButtons={
                            <Button
                              text={"Enviar"}
                              onClick={sendMessage}
                              title="Enviar"
                              loading={sendMessages}
                            />
                          }
                        />
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Chat;
