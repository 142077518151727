import React, { useState, useEffect } from "react";
//import MapPicker from "react-google-map-picker";
import GoogleMapReact from "google-map-react";
import { getAccessTokenApi } from "../../../api/auth";

import {
  Button,
  PageHeader,
  Form,
  Row,
  InputNumber,
  Card,
  Breadcrumb,
  Input,
  Col,
  notification,
  Skeleton,
  Spin,
  Image,
} from "antd";
import {
  SaveOutlined,
  UserOutlined,
  PushpinOutlined,
  AimOutlined,
} from "@ant-design/icons";

import "./Personal.scss";
import { getUsersByIdApi, UpdateUserApi } from "../../../api/user.api";

import SelectCityForm from "../../../components/Admin/City/SelectCityForm/SelectCityForm";
import SelectGenreForm from "../../../components/Admin/Genre/SelectGenreForm/SelectGenreForm";
import { apiKeyGoogle } from "../../../api/config";
import SelectProvinceForm from "../../../components/Admin/Province/SelectProvinceForm";
import SelectCantonForm from "../../../components/Admin/Canton/SelectCantonForm";

import useAuth from "../../../hooks/useAuth";
import Pin from "../../../assets/img/png/pin.png";
//import Marker from "../../../components/Common/marker";

const DefaultLocation = { lat: -0.5012984084076219, lng: -76.97750904233827 };
const DefaultZoom = 13;

const Personal = ({ socket }) => {
  const { user, isLoading } = useAuth(); //hook
  const [formPersonal] = Form.useForm();
  const [personals, setPersonals] = useState([]);
  const token = getAccessTokenApi();
  const [reloadPersonals, setReloadPersonals] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [searchlocation, setSearchLocation] = useState(false);

  const [loadings, setLoadings] = useState(false);
  const [userData, setUserData] = useState({});
  const [city, setCity] = useState();
  const [canton, setCanton] = useState();
  const [province, setProvince] = useState();
  const [genre, setGenre] = useState();
  const [MapControl, SetMapControl] = useState(
    <Skeleton.Input active={true} />
  );
  const [loadingsMap, setLoadingsMap] = useState(true);

  const [lastsocket, setLastsocket] = useState();

  useEffect(() => {
    socket.on("friendrequestResponse", (data) => {
      console.log(data);

      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
    socket.on("messageResponse", (data) => {
      if (user.id === data.destiny && lastsocket !== socket.id) {
        setLastsocket(socket.id);
        notification["info"]({
          message: data.content,
        });
      }
    });
  }, [socket]);

  useEffect(() => {
    setLoadingData(true);
    getUsersByIdApi(token, user.id).then((response) => {
      setPersonals(response.user);
      //console.log(response.user);
      formPersonal.setFieldsValue(response.user);
      //Start the timer
      const initialLocation = {
        lat: response.user.lat,
        lng: response.user.lon,
      };
      setLocation(initialLocation);
      //setDefaultLocation(initialLocation);
      //MapPicker.bind();
      if (loadingsMap) {
        // SetMapControl(
        //   <MapPicker
        //     defaultLocation={defaultLocation}
        //     zoom={zoom}
        //     mapTypeId="roadmap"
        //     style={{ height: "300px" }}
        //     onChangeLocation={handleChangeLocation}
        //     onChangeZoom={handleChangeZoom}
        //     // apiKey="AIzaSyDy8AE5RqF-ozGiI8J7r-U1vnb3AwQCY-o"
        //     apiKey={apiKeyGoogle}
        //   />
        // );
        setLoadingsMap(false);
        // setTimeout(() => {
        //   console.log(location);

        // }, 3000);
      }
      setProvince(response.user.provinceId);
      setCity(response.user.cityId);
      setGenre(response.user.genreId);
      setLoadingData(false);
    });

    setReloadPersonals(false);
  }, []);

  const onFinish = (values) => {
    setLoadings(true);
    const userAdd = {
      ...values,
      lat: location.lat,
      lon: location.lng,
    };
    setUserData(userAdd);

    UpdateUserApi(token, userAdd, user.id).then((result) => {
      if (result.status) {
        setUserData(result.user);
        notification["success"]({
          message: result.message,
        });
        setLoadings(false);
      } else {
        notification["error"]({
          message: result.message,
        });
        setLoadings(false);
      }
    });

    // console.log("Success values:", values);
    // console.log("Success:", userData);
    //next();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message(errorInfo);
    notification["error"]({
      message: "Revisa los errores en su información personal",
      duration: 3,
    });
  };

  function currentLocation() {
    setSearchLocation(true);
    var options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };

    navigator.geolocation.getCurrentPosition(success, error, options);
    // navigator.geolocation.getCurrentPosition(function (position) {
    //   // console.log("Latitude is :", position.coords.latitude);
    //   // console.log("Longitude is :", position.coords.longitude);
    //   setDefaultLocation({
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   });
    //   setLocation({
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   });
    //   setSearchLocation(false);
    // });
  }

  function success(pos) {
    var crd = pos.coords;
    // setDefaultLocation({
    //   lat: crd.latitude,
    //   lng: crd.longitude,
    // });
    setLocation({
      lat: crd.latitude,
      lng: crd.longitude,
    });
    setSearchLocation(false);
    // console.log('Your current position is:');
    // console.log('Latitude : ' + crd.latitude);
    // console.log('Longitude: ' + crd.longitude);
    // console.log('More or less ' + crd.accuracy + ' meters.');
  }

  function error(err) {
    setSearchLocation(false);
    notification["success"]({
      message: `No se puede obtener la ubicación (Error: ${err.message})`,
    });
    // console.warn('ERROR(' + err.code + '): ' + err.message);
  }

  function handleChangeLocation(ev) {
    console.log(ev);
    setLocation({ lat: ev.lat, lng: ev.lng });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  return (
    <div className="personal">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Emprendedor</Breadcrumb.Item>
        <Breadcrumb.Item>Perfil</Breadcrumb.Item>
        <Breadcrumb.Item>Datos personales</Breadcrumb.Item>
      </Breadcrumb>
      <Form
        form={formPersonal}
        name="personal"
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <PageHeader
          className="site-page-header header-left"
          // onBack={() => null}
          style={{ textAlign: "left", justifyContent: "start" }}
          title={
            <>
              <UserOutlined style={{ fontSize: "30px" }} />
              <span> Datos personales</span>
            </>
          }
          // subTitle="This is a subtitle"
        />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item
              label="Cédula"
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name="ci"
              rules={[
                { required: true, message: "Ingrese su cédula de identidad" },
                {
                  type: "string",
                  min: 10,
                  message: "Su cédula de identidad no es correcta",
                },
              ]}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Cédula de identidad"
                //value={userData.name}
                maxLength={10}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={9} xl={9}>
            <Form.Item
              label="Nombres"
              name="name"
              rules={[{ required: true, message: "Ingrese sus nombres" }]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Nombres completos"
                maxLength={50}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={9} xl={9}>
            <Form.Item
              label="Apellidos"
              name="lastname"
              rules={[{ required: true, message: "Ingrese sus apellidos" }]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Apellidos completos"
                //value={userData.name2}
                maxLength={50}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name2: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item
              label="Correo"
              name="email"
              rules={[
                { required: true, message: "Ingrese su correo electrónico" },
                {
                  type: "email",
                  message: "El correo ingresado no es correcto",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Correo electrónico"
                //value={userData.name}
                disabled
                maxLength={50}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={7} xl={7}>
            <Form.Item
              label="Centro de estudio"
              name="university"
              rules={[
                {
                  required: true,
                  message:
                    "Ingrese tu centro de estudio - Universidad - Tecnología",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Centro de estudio - Universidad - Tecnología"
                maxLength={50}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={7}>
            <Form.Item
              label="Carrera cursada"
              name="career"
              rules={[
                {
                  required: true,
                  message: "Ingrese su carrera cursada",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Carrera cursada"
                maxLength={50}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={5} xl={4}>
            <Form.Item
              label="Año de graduación"
              name="year"
              // rules={[
              //   {
              //     required: true,
              //     message: "Ingrese su año de graduación",
              //   },
              // ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <InputNumber
                //prefix={<UserOutlined />}
                placeholder="Año de graduación"
                maxLength={4}
                //value={userData.name}
                //defaultValue={1999}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <SelectProvinceForm
              atributoData={province}
              setAtributoData={setProvince}
            ></SelectProvinceForm>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <SelectCantonForm
              atributoData={canton}
              provinceData={province}
              setAtributoData={setCanton}
            ></SelectCantonForm>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <SelectCityForm
              atributoData={city}
              provinceData={province}
              setAtributoData={setCity}
            ></SelectCityForm>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <SelectGenreForm
              atributoData={genre}
              setAtributoData={setGenre}
            ></SelectGenreForm>
          </Col>
        </Row>
        <PageHeader
          className="site-page-header header-left"
          // onBack={() => null}
          style={{ textAlign: "left", justifyContent: "start" }}
          // onBack={() => null}
          title={
            <>
              <PushpinOutlined style={{ fontSize: "30px" }} />{" "}
              <span> Ubicación</span>
            </>
          }
          // subTitle="This is a subtitle"
        />
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card
              title={
                <span>
                  Por favor, en el mapa presione en su ubicación de contacto
                  para mover el puntero
                </span>
              }
              extra={
                <Button
                  type="dashed"
                  onClick={() => currentLocation()}
                  icon={<AimOutlined />}
                  size="small"
                  className="mini"
                  loading={searchlocation}
                >
                  buscar mi ubicación
                </Button>
              }
              bodyStyle={{ padding: "0" }}
              //style={{ height: "300px", width: "100%" }}
            >
              {/* <MapPicker
                defaultLocation={defaultLocation}
                zoom={zoom}
                mapTypeId="roadmap"
                style={{ height: "300px" }}
                onChangeLocation={handleChangeLocation}
                onChangeZoom={handleChangeZoom}
                // apiKey="AIzaSyDy8AE5RqF-ozGiI8J7r-U1vnb3AwQCY-o"
                apiKey={apiKeyGoogle}
              /> */}
              <div style={{ height: "300px", width: "100%" }}>
                <GoogleMapReact
                  defaultZoom={zoom}
                  defaultCenter={defaultLocation}
                  bootstrapURLKeys={{
                    key: apiKeyGoogle,
                    language: "es",
                    region: "ec",
                    libraries: [],
                  }}
                  onClick={handleChangeLocation}
                  center={location}
                >
                  {!loadingData ? (
                    <Image
                      width={48}
                      src={Pin}
                      lat={location.lat}
                      lng={location.lng}
                      preview={false}
                      style={{ marginTop: "-48px", marginLeft: "-24px" }}
                    />
                  ) : (
                    // <GoogleMapReact.Marker
                    //   key={"mylocation"}
                    //   text={"Mi ubicación"}
                    //   lat={location.lat}
                    //   lng={location.lng}
                    // />
                    <Spin width={100} lat={location.lat} lng={location.lng} />
                  )}
                </GoogleMapReact>
              </div>
            </Card>
          </Col>
        </Row>
        <br></br>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                className="btnBig"
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loadings}
              >
                Guardar <SaveOutlined />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Personal;
