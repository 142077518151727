import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  InputNumber,
  Radio,
} from "antd";

import { getAccessTokenApi } from "../../../../../api/auth";
import {
  UpdateNeedApi,
  AddNeedApi,
  DeleteNeedApi,
} from "../../../../../api/need.api";
//import useAuth from "../../../../../hooks/useAuth";

import "./EditNeedForm.scss";
import SelectKnowledgeForm from "../../../../../components/Admin/Knowledge/SelectKnowledgeForm";
import { getEntrepreneurshipByIdApi } from "../../../../../api/entrepreneurship.api";

export default function EditNeedForm(props) {
  const {
    need,
    setIsVisibleModal,
    setReloadNeeds,
    buttonName,
    addSlag,
    entrepreneurshipData,
  } = props;
  const [needData, setNeedData] = useState({});
  //const { user } = useAuth();
  //const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const token = getAccessTokenApi();
  const [formNeed] = Form.useForm();

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    // setNeedData({
    //   code: "",
    //   name: "",
    // });
  };

  useEffect(() => {
    if (need) {
      formNeed.setFieldsValue(need);
    } else {
      formNeed.setFieldsValue({
        entrepreneurshipId: null,
        hours: null,
        id: null,
        knowledgeId: null,
        other: null,
        years: null,
      });
    }
    // getEntrepreneurshipByIdApi(token, user.id).then((response) => {
    //   setEntrepreneurshipData(response.entrepreneurship);
    // });
  }, [need]);

  const addUpdateNeed = (e) => {
    e.preventDefault();
    let needUpdate = needData;
    if (!needUpdate.name || !needUpdate.code) {
      notification["error"]({
        message: "El nombre y el código es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddNeedApi(token, needUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadNeeds(true);
        setIsVisibleModal(false);
        //resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateNeedApi(token, needUpdate, need.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadNeeds(true);
        setIsVisibleModal(false);
        setNeedData({
          ...needData,
        });
      });
    } else {
      DeleteNeedApi(token, need.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadNeeds(true);
        setIsVisibleModal(false);
      });
    }
  };

  const onFinish = (values) => {
    const token = getAccessTokenApi();
    if (addSlag === "add") {
      AddNeedApi(token, {
        ...values,
        entrepreneurshipId: entrepreneurshipData.id,
      }).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadNeeds(true);
        setIsVisibleModal(false);
        //resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateNeedApi(
        token,
        { ...values, entrepreneurshipId: entrepreneurshipData.id },
        need.id
      ).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadNeeds(true);
        setIsVisibleModal(false);
        setNeedData({
          ...needData,
        });
      });
    } else {
      DeleteNeedApi(token, need.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadNeeds(true);
        setIsVisibleModal(false);
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message(errorInfo);
    notification["error"]({
      message: "Revisa los errores en la información ingresada",
      duration: 3,
    });
  };

  return (
    <div className="edit-need-form">
      <Form
        form={formNeed}
        name="need"
        className="form-edit"
        //onSubmit={addUpdateNeed}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{ remember: false }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <SelectKnowledgeForm
              // atributoData={city}
              // setAtributoData={setCity}
              //handleChange={handleChangeYotengo}
              name={"knowledgeId"}
              nameOther={"other"}
              edit={addSlag === "edit" || addSlag === "delete"}
              values={need}
            ></SelectKnowledgeForm>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name="hours"
              label="Horas requeridas"
              rules={[
                {
                  required: true,
                  message: "Seleccione las horas requeridas",
                },
              ]}
            >
              {/* <Input placeholder="Horas requeridas" /> */}
              {/* <InputNumber
                              placeholder="Horas requeridas"
                              maxLength={4}
                            /> */}
              <Radio.Group>
                <Radio value={2}>2</Radio>
                <Radio value={4}>4</Radio>
                <Radio value={6}>6</Radio>
                <Radio value={8}>8</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {/* <Col xs={22} sm={22} md={7} lg={7} xl={7}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              name="years"
              label="Años de experiencia"
              rules={[
                {
                  required: true,
                  message: "Ingrese los años de experiencia",
                },
              ]}
            >
              <InputNumber placeholder="Años de experiencia" maxLength={4} />
            </Form.Item>
          </Col> */}
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
