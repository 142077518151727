import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Menu, Tooltip } from "antd";

import HomeOutlined from "@ant-design/icons/HomeOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import ArrowRightOutlined from "@ant-design/icons/ArrowRightOutlined";
import PieChartOutlined from "@ant-design/icons/PieChartOutlined";
import FileTextOutlined from "@ant-design/icons/FileTextOutlined";
import GlobalOutlined from "@ant-design/icons/GlobalOutlined";
import DashboardOutlined from "@ant-design/icons/DashboardOutlined";

//import FileOutlined from "@ant-design/icons/FileOutlined";

// import BankOutlined from "@ant-design/icons/BankOutlined";
// import BarsOutlined from "@ant-design/icons/BarsOutlined";

import "./MenuSider.scss";

function MenuSiderUser(props) {
  const { Sider } = Layout;
  //const { SubMenu } = Menu;
  const { menuCollapsed } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [key, setKey] = useState(location.pathname);
  const [group, setGroup] = useState([]);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem("Dashboard", "/admin", <HomeOutlined />),
    getItem("Registros", "/admin/user/registros", <UserOutlined />, [
      getItem("Tiendas", "/admin/tiendas", <ArrowRightOutlined />),
    ]),
    getItem("Gráficas", "/admin/graficas", <PieChartOutlined />, [
      getItem(
        "Encargados y personal de apoyo",
        "/admin/empleados",
        <ArrowRightOutlined />
      ),
      getItem(
        "Tiendas físicas",
        "/admin/tiendasfisicas",
        <ArrowRightOutlined />
      ),
      getItem(
        "Tiendas On-line",
        "/admin/tiendasonline",
        <ArrowRightOutlined />
      ),
      getItem("Ferias", "/admin/ferias", <ArrowRightOutlined />),
      getItem("Eventos", "/admin/eventos", <ArrowRightOutlined />),
    ]),
    getItem("Mapas", "/admin/mapas", <GlobalOutlined />, [
      getItem("Tiendas", "/admin/mapatiendas", <ArrowRightOutlined />),
    ]),
  ];
  const onClick = (e) => {
    console.log("click ", e);
    setGroup(e.keyPath);
    setKey(e.key);
    navigate(e.key);
    // if (e.key === "/admin/mapusuario" || e.key === "/admin/mapemprendimiento") {
    //   setGroup("/admin/mapas");
    //   navigate(0);
    // }
  };
  //console.log(location);
  return (
    <Sider width={256} theme="light" collapsed={menuCollapsed}>
      <Menu
        onClick={onClick}
        style={{
          marginTop: 65,
        }}
        //inlineCollapsed={false}
        defaultSelectedKeys={[key]}
        defaultOpenKeys={group}
        mode="inline"
        items={items}
      />
    </Sider>
  );
}

export default MenuSiderUser;
